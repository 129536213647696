import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import { ModalProps } from 'components/Modal/types';
import { BackIcon } from 'resources/icons';

function Sidebar(props: ModalProps) {
  /**
   * Props
   */
  const { 'data-testid': testId } = props;

  /**
   * Render
   */
  return (
    <Transition show={props.open} as="div" className="absolute">
      <div
        className="fixed left-0 top-0 right-0 bottom-0 flex items-center justify-center z-50"
        data-testid={`${testId}-wrapper`}
      >
        <Transition.Child
          unmount={false}
          as="div"
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute left-0 top-0 right-0 bottom-0 bg-black/25 -z-10"
            onClick={() => !props.disableClose && props.onClose?.()}
            data-testid={`${testId}-bg`}
          />
        </Transition.Child>
        <Transition.Child
          unmount={false}
          as="div"
          className="absolute top-0 bottom-0 right-0 z-0 bg-white shadow-lg"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-96"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-300"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-96"
        >
          <div
            className={clsx('w-[300px] h-full flex flex-col', props.className)}
            data-testid={`${testId}-container`}
          >
            <div className="flex w-full shadow-lg p-4 gap-4">
              <button
                onClick={props.onClose}
                disabled={props.disableClose}
                data-testid={`${testId}-close-button`}
                className="text-primary-1-100 disabled:text-secondary-2-30"
              >
                <BackIcon />
              </button>
              <span
                className="flex-1 text-2xl font-medium pr-4 text-primary-1-100"
                data-testid={`${testId}-title`}
              >
                {props.title ?? ''}
              </span>
            </div>
            <div className="relative flex-1">{props.children}</div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}

export default Sidebar;
