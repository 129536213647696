import CatalogPage from 'pages/Catalog/CatalogPage';
import CatalogProvider from 'pages/Catalog/CatalogProvider';

/**
 * Component
 */
function Catalog() {
  /**
   * Render
   */
  return (
    <CatalogProvider>
      <CatalogPage />
    </CatalogProvider>
  );
}
export default Catalog;
