import { Maybe } from 'yup';

import { APIOptions } from 'api/hooks/useApiBase';
import { useApiMutation, UseAPIMutationProps } from 'api/hooks/useApiMutation';
import { SuccessResponse } from 'api/core';

/**
 * Types
 */
// Request Body
export type LoginRequest = {
  email: string;
  password: string;
};
// Response
export type LoginResponse = SuccessResponse & {
  sessionId: Maybe<string>;
};
export type LogoutResponse = SuccessResponse & {
  message: Maybe<string>;
};

/**
 * APIs
 */
// 🔵 POST security/login
export function useApiLogin(options?: APIOptions<LoginResponse>) {
  // Props
  const apiProps: UseAPIMutationProps<LoginResponse> = {
    url: 'security/login',
    kind: 'post',
    options: { ...options, auth: false },
    header: {}
  };
  // API
  const api = useApiMutation<LoginResponse>(apiProps);
  const call = async (body: LoginRequest) =>
    await api.call({ ...apiProps, body });
  return { ...api, call };
}

// 🔵 POST security/logout
export function useApiLogout(options?: APIOptions<LogoutResponse>) {
  // Props
  const apiProps: UseAPIMutationProps<LogoutResponse> = {
    url: 'security/logout',
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };
  // API
  const api = useApiMutation<LogoutResponse>(apiProps);
  const call = async () => await api.call({ ...apiProps });
  return { ...api, call };
}
