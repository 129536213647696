import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Dropdown, DropdownItem } from 'components/Dropdown';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import { useUnsavedContext } from 'providers/UnsavedProvider';
import { AddIcon, MapIcon, UploadIcon } from 'resources/icons';
import { generateQueryParam } from 'util/generateQueryParam';

function EditActionsMenu() {
  /**
   * Custom hooks
   */
  const navigate = useNavigate();

  /**
   * Context
   */
  const { data, id, setIsEditingTitle, setUploadOpen, setRegionalOpen } =
    useCatalogContext();
  const { openModal, unsaved } = useUnsavedContext();

  /**
   * Callbacks
   */
  const navigateAddProduct = () => navigate(addProductsUrl);

  /**
   * Dropdown Item Constants
   */
  const defaultDropDownItems: DropdownItem[] = [
    {
      name: 'Add Products',
      icon: <AddIcon />,
      action: unsaved
        ? () => openModal(navigateAddProduct)
        : navigateAddProduct,
      testId: 'catalog_menu-add-products'
    },
    {
      name: 'Upload .csv File',
      icon: <UploadIcon />,
      action: () => {
        setIsEditingTitle(false);
        setRegionalOpen(false);
        setUploadOpen(true);
      },
      testId: 'catalog_menu-upload-file'
    }
  ];
  const templatingDropDownItem: DropdownItem = {
    name: 'Add regional products',
    icon: <MapIcon />,
    action: () => {
      setIsEditingTitle(false);
      setUploadOpen(false);
      setRegionalOpen(true);
    },
    testId: 'catalog_menu-map'
  };

  /**
   * Memo
   */
  // 🔵 Memo - Url for "Add Products"
  // Requires memo due to usage of generateQueryParam
  const addProductsUrl = useMemo(
    () =>
      generateQueryParam('/products/search', {
        cid: id ?? '',
        catalogName: data?.catalog?.name ?? ''
      }),
    [data?.catalog?.name, id]
  );
  // 🔵 Memo - Menu items
  const items: DropdownItem[] = useMemo(
    () =>
      data?.useRegionBasedTemplating
        ? defaultDropDownItems
        : [...defaultDropDownItems, ...[templatingDropDownItem]],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addProductsUrl, unsaved]
  );

  /**
   * Render
   */
  return (
    <Dropdown name="Edit Actions" items={items} data-testid="catalog_menu" />
  );
}

export default EditActionsMenu;
