import HomePage from './HomePage';
import HomeProvider from './HomeProvider';

/**
 * Component
 */
function Home() {
  /**
   * Render
   */
  return (
    <HomeProvider>
      <HomePage />
    </HomeProvider>
  );
}
export default Home;
