import { useCallback, useState } from 'react';

import { Maybe } from 'yup';

import { useApiUploadCsv } from 'api/upload.api';
import { Button } from 'components/Button';
import {
  FileUpload,
  UploadedFileItem,
  UploadedFile
} from 'components/FileUpload';
import { Modal } from 'components/Modal';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import { useUnsavedContext } from 'providers/UnsavedProvider';
import { convertFileList } from 'util/fileProcessor';

function UploadModal() {
  /**
   * Context
   */
  const { data, queryParam, setUploadOpen, submitUploads, uploadOpen } =
    useCatalogContext();
  const { setUnsaved } = useUnsavedContext();
  const customerId = data?.customer?.customerId ?? queryParam.customerId ?? '';

  /**
   * State
   */
  const [uploads, setUploads] = useState<UploadedFile[]>([]);

  /**
   * API
   */
  const uploadApi = useApiUploadCsv(customerId);

  /**
   * Callbacks
   */
  // 🟤 Cb - Closing modal on submit
  const onModalSubmit = useCallback(() => {
    setUploadOpen(false);
    const successUploads = uploads.filter(({ id }) => Boolean(id));
    if (successUploads.length) {
      submitUploads(successUploads);
      setUnsaved(true);
    }
    setUploads([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);
  // 🟤 Cb - File selected then upload
  const onSelectingFiles = async (files: Maybe<FileList>) => {
    const [file] = await convertFileList(files);
    const res = await uploadApi.call(file);
    const errMsg = res?.data?.error ?? "There's an issue uploading the file";
    const error = res?.status !== 200 || res?.data?.error ? errMsg : '';
    const newFile: UploadedFile = {
      id: res?.data?.uploadId,
      name: file.fileName,
      error
    };
    setUploads([newFile, ...uploads]);
  };
  // 🟤 Cb - Delete upload based on the n-th position (index)
  const deleteNthUploads = (index: number) => {
    const mutableUploads = [...uploads];
    mutableUploads.splice(index, 1);
    setUploads(mutableUploads);
  };
  // 🟤 Cb - Apply error to existing upload at n-th position (index)
  const errorNthUploads = (index: number, error: string) => {
    const mutableUploads = [...uploads];
    mutableUploads[index].error = error;
    setUploads(mutableUploads);
  };

  /**
   * Render
   */
  return (
    <Modal
      className="max-w-[592px]"
      open={uploadOpen}
      onClose={onModalSubmit}
      title={
        data?.catalog?.name
          ? `Upload file to ${data?.catalog?.name}`
          : 'Upload file'
      }
      data-testid="catalog_upload"
    >
      <div className="flex flex-col gap-4 mt-4">
        <span className="text-secondary-2-100 text-base">
          You can upload several .csv files but one at a time to a catalog.
        </span>
        <FileUpload
          loading={uploadApi.loading}
          accepts="text/csv"
          displayAccepts=".csv"
          data-testid="catalog_upload-uploader"
          onSelectingFiles={onSelectingFiles}
        />
      </div>
      <div className="my-8 max-h-[180px] overflow-y-auto overflow-x-hidden">
        {uploads.map((item, i) => (
          <UploadedFileItem
            {...item}
            index={i}
            key={`item-${i}`}
            onDelete={deleteNthUploads}
            onError={errorNthUploads}
            data-testid="catalog_upload"
          />
        ))}
      </div>
      <div className="flex justify-end">
        <Button
          type="button"
          title="Submit"
          className="bg-primary-1-100 text-white"
          disabled={uploadApi.loading}
          onClick={onModalSubmit}
          data-testid="catalog_upload-save-button"
        />
      </div>
    </Modal>
  );
}
export default UploadModal;
