import { Maybe } from 'yup';

import { Catalog } from 'api/catalog.api';
import { APIOptions } from 'api/hooks/useApiBase';
import { useApiOnMount, UseAPIOnMountProps } from 'api/hooks/useApiOnMount';
import { useApiMutation, UseAPIMutationProps } from 'api/hooks/useApiMutation';
import { generateQueryParam } from 'util/generateQueryParam';
import { SuccessResponse } from './core';

/**
 * Types
 */
// Data
export type CustomerRegion = {
  id: string;
  name: string;
  customerId: string;
};
export type Customer = {
  id: Maybe<string>;
  customerId: string;
  branchId: Maybe<string>;
  branchName: Maybe<string>;
  erpId: Maybe<string>;
  name: string;
  erpName: Maybe<string>;
  billTo: Maybe<boolean>;
  lastUpdate: Maybe<string>;
  contactName: Maybe<string>;
  contactPhone: Maybe<string>;
  regions: CustomerRegion[];
  catalogs: Catalog[];
};

// Request
export type CustomerListParam = {
  page: number;
  perPage: number;
  phrase: string;
};
export type AddCustomerRequest = {
  customer: Customer;
  uploadIds: string[];
  procurementSystem: string;
};

// Response
export type CustomerListResponse = {
  page: number;
  totalPages: number;
  resultsPerPage: number;
  totalItems: number;
  phrase: string;
  customers: Customer[];
};

/**
 * APIs
 */
// 🔵 GET customer/list/filter
export function useApiCustomerList(
  param: CustomerListParam,
  options?: APIOptions<CustomerListResponse>
) {
  // Props
  const url = 'customer/list/filter';
  const apiProps: UseAPIOnMountProps<CustomerListResponse> = {
    url: generateQueryParam(url, param),
    kind: 'get',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiOnMount<CustomerListResponse>(apiProps);
  const refetch = async (myParam?: CustomerListParam) =>
    await api.refetch({ url: generateQueryParam(url, myParam ?? param) });
  return { ...api, refetch };
}

// 🔵 GET customer/detail/{customerId}
export function useApiCustomerDetail(
  customerId?: string,
  options?: APIOptions<Customer>
) {
  // Props
  const apiProps: UseAPIOnMountProps<Customer> = {
    url: `customer/detail/${customerId}`,
    kind: 'get',
    skip: !customerId,
    options: { ...options, auth: true },
    header: {}
  };
  // API
  const api = useApiOnMount<Customer>(apiProps);
  const refetch = async () => await api.refetch();

  // Output
  return { ...api, refetch };
}

// 🔵 GET customer/search/{id}
export function useApiCustomerSearch(options?: APIOptions<Customer>) {
  // Props
  const defaultUrl = 'customer/search/';
  const apiProps: UseAPIMutationProps<Customer> = {
    url: defaultUrl,
    kind: 'get',
    options: { ...options, auth: true },
    header: {}
  };
  // API
  const api = useApiMutation<Customer>(apiProps);
  const call = async (id: string) =>
    await api.call({ ...apiProps, url: defaultUrl + id });
  // Output
  return { ...api, call };
}

// 🔵 POST customer/new/
export function useApiCustomerAdd(options?: APIOptions<Customer>) {
  // Props
  const defaultUrl = 'customer/new';
  const apiProps: UseAPIMutationProps<Customer, Customer> = {
    url: defaultUrl,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };
  // API
  const api = useApiMutation<Customer, Customer>(apiProps);
  const call = async (body: Customer) => await api.call({ ...apiProps, body });
  // Output
  return { ...api, call };
}

// 🔵 POST v2/customer/new/
export function useApiCustomerAddV2(options?: APIOptions<Customer>) {
  const defaultUrl = 'v2/customer/new';
  const apiProps: UseAPIOnMountProps<Customer, AddCustomerRequest> = {
    url: defaultUrl,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<Customer, AddCustomerRequest>(apiProps);
  const call = async (body: AddCustomerRequest) =>
    await api.call({ ...apiProps, body });
  return { ...api, call };
}

// 🔵 DELETE customer/delete/{customerId}
export function useApiCustomerDelete(
  customerId?: string,
  options?: APIOptions<SuccessResponse>
) {
  // Props
  const defaultUrl = 'customer/delete/';
  const apiProps: UseAPIMutationProps<SuccessResponse> = {
    url: defaultUrl + customerId,
    kind: 'delete',
    options: { ...options, auth: true },
    header: {}
  };
  // API
  const api = useApiMutation<SuccessResponse>(apiProps);
  const call = async (customerId?: string) =>
    await api.call({ ...apiProps, url: defaultUrl + customerId });

  // Output
  return { ...api, call };
}
