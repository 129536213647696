import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { WrapperProps } from '@reece/global-types';
import { noop } from 'lodash-es';

import UnsavedModal from 'providers/subComponents/UnsavedModal';
import { WarningIcon } from 'resources/icons';

/**
 * Types
 */
export type UnsavedModalDisplay = {
  title?: string;
  save?: string;
  icon?: JSX.Element;
  leave?: string;
  body?: ReactNode;
};
export type UnsavedContextType = {
  closeModal: () => void;
  onLeave: () => void;
  onSave: () => void;
  openModal: (onLeave: () => void) => void;
  setModalDisplay: (display: UnsavedModalDisplay) => void;
  setOnLeave: (method: () => void) => void;
  setOnSave: (method: () => void) => void;
  setUnsaved: (state: boolean) => void;
  unsaved: boolean;
};

/**
 * Default values
 */
export const defaultUnsavedContext: UnsavedContextType = {
  closeModal: noop,
  openModal: noop,
  onLeave: noop,
  onSave: noop,
  setModalDisplay: noop,
  setOnLeave: noop,
  setOnSave: noop,
  setUnsaved: noop,
  unsaved: false
};
export const defaultUnsavedModalDisplay: UnsavedModalDisplay = {
  title: 'Unsaved Changes',
  icon: <WarningIcon />,
  save: 'Save all changes',
  leave: 'Leave without saving',
  body: ''
};

/**
 * Context
 */
export const UnsavedContext = createContext(defaultUnsavedContext);
export const useUnsavedContext = () => useContext(UnsavedContext);

/**
 * Provider
 */
function UnsavedProvider({ children }: WrapperProps) {
  /**
   * States
   */
  const [unsaved, setUnsaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalDisplay, setModalDisplay] = useState(defaultUnsavedModalDisplay);
  const [onLeave, setOnLeaveState] = useState<() => void>(noop);
  const [onSave, setOnSaveState] = useState<() => void>(noop);

  /**
   * Callbacks
   */
  // 🟤 Cb - update onLeave method
  const setOnLeave = (action: () => void) => setOnLeaveState(() => action);
  // 🟤 Cb - update onSave method
  const setOnSave = (action: () => void) => setOnSaveState(() => action);
  // 🟤 Cb - open modal
  const openModal = useCallback((action: () => void) => {
    setOnLeave(action);
    setOpen(true);
  }, []);
  // 🟤 Cb - close modal
  const closeModal = () => setOpen(false);

  /**
   * Memo
   */
  const providerValues: UnsavedContextType = useMemo(
    () => ({
      closeModal,
      openModal,
      onLeave,
      onSave,
      setModalDisplay,
      setOnLeave,
      setOnSave,
      setUnsaved,
      unsaved
    }),
    [onLeave, onSave, openModal, unsaved]
  );

  /**
   * Render
   */
  return (
    <UnsavedContext.Provider value={providerValues}>
      {children}
      <UnsavedModal display={modalDisplay} open={open} />
    </UnsavedContext.Provider>
  );
}

export default UnsavedProvider;
