import { useNavigate, useLocation } from 'react-router-dom';

export default function useSearchParam(paramName: string) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params.get(paramName);
}

type ParamConfig = {
  arrayKeys: string[];
};

/**
 * This will return the search params as an object
 * @param delimiter used to parse arrays if provided
 * @param doNotPushHistory (default: false) true: replace history, false: push history
 * @returns Search params as object and setter
 */
export function useQueryParams<T extends object>(
  config: ParamConfig = { arrayKeys: [] },
  toReplaceHistory = false
): [T, (obj: T, path?: string) => void] {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // set
  const setUrlSearchParams = (obj: T, path?: string) => {
    const pathname = path || location.pathname;
    const search = new URLSearchParams(objectToSearchParams(obj)).toString();
    navigate({ pathname, search }, { replace: toReplaceHistory });
  };
  // output
  return [groupParamsByKey(params, config.arrayKeys) as T, setUrlSearchParams];
}

export function objectToSearchParams<T extends object>(record: T) {
  return Object.entries(record)
    .filter(([_, val]) => val)
    .reduce((agg: any[], tuple) => {
      const [key, val] = tuple;
      if (Array.isArray(val)) {
        const vals = val.filter((v) => v).map((v) => [key, v]);
        return vals.length ? [...agg, ...vals] : agg;
      } else {
        return [...agg, tuple];
      }
    }, []);
}

export function groupParamsByKey(params: URLSearchParams, arrayKeys: string[]) {
  return [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if (arrayKeys.includes(key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {} as Record<string, string | string[]>);
}
