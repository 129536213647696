import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  ProcurementSystem,
  useApiProcurementSystemList,
  useApiSaveCatalog
} from 'api/catalog.api';
import { Button } from 'components/Button';
import { LoadingIcon } from 'components/LoadingIcon';
import { Modal } from 'components/Modal';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import { useToastContext } from 'providers/ToastProvider';
import { useUnsavedContext } from 'providers/UnsavedProvider';
import { PunchoutReusableContent } from 'util/contents';
import { generateQueryParam } from 'util/generateQueryParam';

/**
 * Config
 */
const defaultSystems: ProcurementSystem[] = [
  { name: PunchoutReusableContent.EMPTY }
];
const errorToastMsg = (
  <>
    <b>Error:</b> Changes failed to save. Please try again
  </>
);

/**
 * Component
 */
function SaveUploadModal() {
  /**
   * Custom hooks
   */
  const navigate = useNavigate();

  /**
   * Context
   */
  const {
    data,
    queryParam,
    saveModalOpen,
    setSaveModalOpen,
    setUploadIds,
    title,
    uploadIds
  } = useCatalogContext();
  const { toast } = useToastContext();
  const { setUnsaved } = useUnsavedContext();
  const customerId = data?.customer?.id ?? queryParam.cid ?? '';

  /**
   * States
   */
  const [procurementSystems, setProcurementSystems] = useState(defaultSystems);
  const [selectedSystem, setSelectedSystem] = useState(defaultSystems[0]);

  /**
   * API
   */
  // 🟣 API - Procurement System
  const procurementSystemsApi = useApiProcurementSystemList({
    onCompleted: ({ data: { procurementSystems: sys } }) => {
      const filteredSystems = sys.filter(({ name }) => Boolean(name));
      setProcurementSystems(filteredSystems);
      setSelectedSystem(sys[0]);
    }
  });
  // 🟣 API - Save catalog
  const saveCatalogApi = useApiSaveCatalog(customerId);
  const loading = procurementSystemsApi.loading || saveCatalogApi.loading;

  /**
   * Callbacks
   */
  // 🟤 CB - Close Modal
  const closeModal = () => setSaveModalOpen(false);
  // 🟤 CB - Call save catalog
  const saveButtonClick = async () => {
    const res = await saveCatalogApi.call({
      procurementSystem: selectedSystem.name,
      name: title,
      uploadIds
    });
    if (!res?.data.success || !res.data.catalogId) {
      toast({ message: errorToastMsg, kind: 'error' });
      return;
    }
    setUploadIds([]);
    setUnsaved(false);
    closeModal();

    const url = `/catalog/detail/${res.data.catalogId}`;
    navigate(generateQueryParam(url, { ...queryParam }), { replace: true });
    navigate(0);
  };

  /**
   * Render
   */
  return (
    <Modal
      className="max-w-[592px]"
      open={saveModalOpen}
      onClose={closeModal}
      disableClose={loading}
      title="Save Changes to Catalog"
      data-testid="catalog_save"
    >
      {!saveCatalogApi.loading && (
        <div className="flex flex-col gap-4 mt-4">
          <span className="text-secondary-2-100 text-base">
            Please select which <strong>procurement system</strong> you would
            like to send the changes to:
          </span>
          <form className="flex gap-4">
            {procurementSystems.map((system, index) => (
              <div key={system.name}>
                <label>
                  <input
                    type="radio"
                    value={system.name}
                    checked={selectedSystem === system}
                    onChange={() => setSelectedSystem(system)}
                    className="mx-2"
                    data-testid={`catalog_save-radio${index}`}
                  />
                  {system.name}
                </label>
              </div>
            ))}
          </form>
        </div>
      )}
      <p className="text-secondary-2-100 mt-4 mb-6">
        Note: It may take a few moments for the Catalog to sync.
      </p>
      {saveCatalogApi.loading && (
        <div className="flex justify-center items-center mb-4">
          <LoadingIcon size="xxl" data-testid="catalog_save-loading" />
        </div>
      )}
      <div className="flex justify-end gap-4">
        <Button
          type="button"
          title="No, back to catalog"
          disabled={loading}
          className="bg-white text-primary-2-100 underline"
          onClick={closeModal}
          data-testid="catalog_save-exit-button"
        />
        <Button
          type="button"
          title="Save all changes"
          className="bg-primary-1-100 text-white font-medium"
          disabled={loading}
          onClick={saveButtonClick}
          data-testid="catalog_save-save-button"
        />
      </div>
    </Modal>
  );
}

export default SaveUploadModal;
