import { Button } from 'components/Button';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import SaveDeleteModal from 'pages/Catalog/SaveDeleteModal';
import SaveUploadModal from 'pages/Catalog/SaveUploadModal';

/**
 * Component
 */
function CatalogSave() {
  /**
   * Context
   */
  const { deletedProducts, setSaveModalOpen, uploadIds } = useCatalogContext();

  /**
   * Render
   */
  return (
    <>
      <Button
        type="button"
        title="Save Changes"
        className="mb-5 bg-primary-1-100 text-white"
        disabled={!uploadIds.length && !deletedProducts.length}
        onClick={() => setSaveModalOpen(true)}
        data-testid="catalog_save-button"
      />
      {uploadIds.length ? <SaveUploadModal /> : <SaveDeleteModal />}
    </>
  );
}
export default CatalogSave;
