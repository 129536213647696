import { useState } from 'react';

import clsx from 'clsx';

import { Button } from 'components/Button';
import { DropdownProps } from 'components/Dropdown';
import { ElipsesIcon } from 'resources/icons';

/**
 * Component
 */
function Dropdown(props: DropdownProps) {
  /**
   * States
   */
  const [open, setOpen] = useState(false);

  /**
   * Callbacks
   */
  const toggleOpen = () => setOpen(!open);

  /**
   * Render
   */
  return (
    <div className="relative">
      <Button
        onClick={toggleOpen}
        className={clsx('text-primary-1-100', props.className)}
        iconPosition="right"
        icon={<ElipsesIcon />}
        data-testid={props['data-testid']}
      >
        {props.name}
      </Button>
      {open && (
        <>
          <div
            className="z-10 fixed top-0 left-0 right-0 bottom-0"
            onClick={toggleOpen}
          />
          <div
            className="absolute right-0 origin-top-right py-2 z-50 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5"
            role="menu"
          >
            {props.items.map((item, index) => {
              const menuClick = () => {
                item.action();
                toggleOpen();
              };
              return (
                <span
                  onClick={menuClick}
                  className="text-primary-1-100 block px-6 py-2 cursor-pointer hover:bg-primary-1-10"
                  role="menuitem"
                  key={item.name}
                  data-testid={item.testId || `${props['data-testid']}${index}`}
                >
                  <div className="flex items-center text-base text-medium whitespace-nowrap">
                    <span className="inline-block pr-2">{item.icon}</span>
                    <span>{item.name}</span>
                  </div>
                </span>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default Dropdown;
