import { useApiUploadCsv } from 'api/upload.api';
import {
  FileUpload,
  UploadedFile,
  UploadedFileItem
} from 'components/FileUpload';
import { InputLabel } from 'components/Input';
import { Dispatch } from 'react';
import { HelpIcon } from 'resources/icons';
import { convertFileList } from 'util/fileProcessor';
import { Maybe } from 'yup';

/**
 * Types
 */
export type CreateCustomerUploadProps = {
  isUpload?: boolean;
  loading: boolean;
  disabled: boolean;
  setIsUpload: Dispatch<boolean>;
  setUploads: Dispatch<UploadedFile[]>;
  uploads: UploadedFile[];
};

/**
 * Component
 */
function CreateCustomerUpload(props: CreateCustomerUploadProps) {
  /**
   * API
   */
  // 🟣 API - Upload
  const uploadApi = useApiUploadCsv();

  /**
   * Callbacks
   */
  // 🟤 Cb - File selected then upload
  const onSelectingFiles = async (files: Maybe<FileList>) => {
    const [file] = await convertFileList(files);
    const res = await uploadApi.call(file);
    const errMsg = res?.data?.error ?? "There's an issue uploading the file";
    const error = res?.status !== 200 || res?.data?.error ? errMsg : '';
    const newFile: UploadedFile = {
      id: res?.data?.uploadId,
      name: file.fileName,
      error
    };
    props.setUploads([newFile, ...props.uploads]);
  };
  // 🟤 Cb - Delete upload based on the n-th position (index)
  const deleteNthUploads = (index: number) => {
    const mutableUploads = [...props.uploads];
    mutableUploads.splice(index, 1);
    props.setUploads(mutableUploads);
  };
  // 🟤 Cb - Apply error to existing upload at n-th position (index)
  const errorNthUploads = (index: number, error: string) => {
    const mutableUploads = [...props.uploads];
    mutableUploads[index].error = error;
    props.setUploads(mutableUploads);
  };

  /**
   * Render
   */
  return (
    <div className="pb-8">
      <InputLabel
        label="Select product catalog method: "
        required
        className="font-medium text-xl"
      />
      <p className="pt-4">
        If you already have a product catalog for your customer, you may upload
        it here. If you do NOT have a product catalog, selecting assigned
        territory(ies) will create a base catalog automatically.
      </p>
      <div className="flex gap-16 py-8">
        <label>
          <input
            type="radio"
            checked={Boolean(props.isUpload)}
            disabled={props.disabled}
            onChange={() => props.setIsUpload(true)}
            className="mr-4"
            data-testid="create_customer-radio-upload"
          />
          Upload .CSV
        </label>
        <label>
          <input
            type="radio"
            checked={!(props.isUpload ?? true)}
            disabled={props.disabled}
            onChange={() => props.setIsUpload(false)}
            className="mr-4"
            data-testid="create_customer-radio-assigned"
          />
          <span className="inline-flex relative justify-center gap-2">
            Use assigned territory(ies)
            <span
              data-tooltip-target="tooltip-territory"
              className="group inline-block text-primary-1-100"
            >
              <span>
                <HelpIcon />
              </span>
              <span
                id="tooltip-territory"
                role="tooltip"
                className="absolute z-10 px-4 py-2 text-white transition-all duration-300 bg-primary-3-100 rounded shadow-sm opacity-0 group-hover:opacity-100 w-[322px] top-8"
              >
                Territory(ies) are automatically assigned based on the
                customer’s home branch.
                <div className="tooltip-arrow" data-popper-arrow />
              </span>
            </span>
          </span>
        </label>
      </div>
      {Boolean(props.isUpload) && (
        <div>
          <FileUpload
            loading={uploadApi.loading}
            accepts="text/csv"
            displayAccepts=".csv"
            data-testid="create_customer-uploader"
            onSelectingFiles={onSelectingFiles}
          />
          {props.uploads.map((item, i) => (
            <UploadedFileItem
              {...item}
              index={i}
              key={`item-${i}`}
              onDelete={deleteNthUploads}
              onError={errorNthUploads}
              data-testid="create_customer"
            />
          ))}
        </div>
      )}
    </div>
  );
}
export default CreateCustomerUpload;
