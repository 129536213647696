import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { WrapperProps } from '@reece/global-types';
import { noop } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Maybe } from 'yup';

import {
  CatalogDetailParam,
  CatalogDetailRequest,
  CatalogDetailResponse,
  ProductCatalog,
  useApiCatalogDetail
} from 'api/catalog.api';
import { Product } from 'api/product.api';
import { SelectFilter } from 'common/ProductFilters/types';
import {
  clearSelectFilters,
  convertSelectedFilterMapToArray
} from 'common/ProductFilters/util';
import { UploadedFile } from 'components/FileUpload';
import { useUnsavedContext } from 'providers/UnsavedProvider';
import { PunchoutReusableContent } from 'util/contents';
import { configuration } from 'util/configurations';
import { useQueryParams } from 'util/hooks/useSearchParam';

/**
 * Types
 */
export type CatalogQueryParam = {
  page?: string;
  phrase?: string;
  cid?: string;
  customerId?: string;
  customerName?: string;
};
export type CatalogContextType = {
  data?: Maybe<CatalogDetailResponse>;
  deletedProducts: ProductCatalog[];
  filters: SelectFilter[];
  id?: string;
  isEditingTitle: boolean;
  loading: boolean;
  onSearch: (phrase?: string) => void;
  queryParam: CatalogQueryParam;
  refetchDetail: (
    body: CatalogDetailRequest,
    myParams?: Partial<CatalogDetailParam>
  ) => void;
  resetSearch: () => void;
  saveModalOpen: boolean;
  setDeletedProducts: Dispatch<ProductCatalog[]>;
  setFilters: Dispatch<SelectFilter[]>;
  setIsEditingTitle: Dispatch<boolean>;
  setSaveModalOpen: Dispatch<boolean>;
  setTitle: Dispatch<string>;
  setUploadIds: Dispatch<string[]>;
  setUploadOpen: Dispatch<boolean>;
  setRegionalOpen: Dispatch<boolean>;
  submitUploads: Dispatch<UploadedFile[]>;
  title: string;
  toggleDeleteProduct: (product: ProductCatalog) => void;
  updatePage: (newPage: number) => void;
  uploadIds: string[];
  uploadOpen: boolean;
  regionalOpen: boolean;
};

/**
 * Config
 */
export const CATALOG_CONFIG = {
  perPage: configuration.itemsPerPage,
  defaultName: 'New Catalog 1'
};
export const defaultCatalogContext: CatalogContextType = {
  deletedProducts: [],
  filters: [],
  isEditingTitle: false,
  loading: false,
  onSearch: noop,
  queryParam: {},
  refetchDetail: noop,
  resetSearch: noop,
  saveModalOpen: false,
  setDeletedProducts: noop,
  setFilters: noop,
  setIsEditingTitle: noop,
  setSaveModalOpen: noop,
  setTitle: noop,
  setUploadIds: noop,
  setUploadOpen: noop,
  setRegionalOpen: noop,
  submitUploads: noop,
  title: CATALOG_CONFIG.defaultName,
  toggleDeleteProduct: noop,
  updatePage: noop,
  uploadIds: [],
  uploadOpen: false,
  regionalOpen: false
};
export const productRemovedMessage = (product: Maybe<Product>) => (
  <span className="flex gap-2">
    <b>Product removed:</b>
    <span className="max-w-[30ch] inline-block truncate">
      {product?.name ?? PunchoutReusableContent.EMPTY}
    </span>
    <span>{`(#${product?.partNumber ?? PunchoutReusableContent.EMPTY})`}</span>
  </span>
);
const { defaultName, perPage } = CATALOG_CONFIG;

/**
 * Context
 */
export const CatalogContext = createContext(defaultCatalogContext);
export const useCatalogContext = () => useContext(CatalogContext);

/**
 * Provider
 */
function CatalogProvider({ children }: WrapperProps) {
  /**
   * Context
   */
  const { setUnsaved } = useUnsavedContext();

  /**
   * Custom hooks
   */
  const { id } = useParams<{ id: string }>();
  const [queryParam, setQueryParam] = useQueryParams<CatalogQueryParam>();
  const gpPage = parseInt(queryParam.page ?? '1');

  /**
   * States
   */
  const [deletedProducts, setDeletedProducts] = useState<ProductCatalog[]>([]);
  const [uploadIds, setUploadIds] = useState<string[]>([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [regionalOpen, setRegionalOpen] = useState(false);
  const [title, setTitle] = useState(defaultName);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [filters, setFilters] = useState<SelectFilter[]>([]);

  /**
   * API
   */
  const apiParams: CatalogDetailParam = useMemo(
    () => ({ catalogId: id, perPage, page: gpPage }),
    [gpPage, id]
  );
  const apiBody: CatalogDetailRequest = useMemo(
    () => ({
      filters: convertSelectedFilterMapToArray(filters),
      phrase: queryParam.phrase,
      uploadIds
    }),
    [filters, queryParam.phrase, uploadIds]
  );
  const api = useApiCatalogDetail(apiBody, apiParams, {
    onCompleted: ({ data }) => {
      const fromData = data.catalog?.name ?? defaultName;
      setTitle(title === defaultName ? fromData : title);
    }
  });

  /**
   * Callbacks
   */
  // 🟤 Cb - Reload catalog detail
  const refetchDetail = useCallback(
    (body: CatalogDetailRequest, myParams?: Partial<CatalogDetailParam>) => {
      const refetchParams: CatalogDetailParam = { ...apiParams, ...myParams };
      id && (refetchParams.catalogId = id);
      api.refetch(refetchParams, body);
    },
    [apiParams, id, api]
  );
  // 🟤 Cb - When clicking submit after uploading
  const submitUploads = useCallback(
    (uploads: UploadedFile[]) => {
      const uIds = uploads.map(({ id }) => id ?? '');
      const myBody = { ...apiBody, uploadIds: uIds };
      setUploadIds(uIds);
      setQueryParam({ ...queryParam, page: '1' });
      refetchDetail(myBody, { page: 1 });
    },
    [apiBody, queryParam, refetchDetail, setQueryParam]
  );
  // 🟤 Cb - To update page then refetch
  const updatePage = useCallback(
    (newPage: number) => {
      setQueryParam({ ...queryParam, page: `${newPage}` });
      refetchDetail(apiBody, { page: newPage });
    },
    [apiBody, queryParam, refetchDetail, setQueryParam]
  );
  // 🟤 Cb - Toggle delete product by Id
  const toggleDeleteProduct = useCallback(
    (product: ProductCatalog) => {
      // Find if the product is in the list
      const index = deletedProducts.findIndex(({ id }) => id === product.id);
      // If it's not found, add products
      if (index === -1) {
        setUnsaved(true);
        setDeletedProducts([...deletedProducts, product]);
        return;
      }
      // If found, splice out the data at n-th index
      const mutableDeletedProducts = [...deletedProducts];
      mutableDeletedProducts.splice(index, 1);
      setUnsaved(Boolean(mutableDeletedProducts.length));
      setDeletedProducts(mutableDeletedProducts);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletedProducts]
  );
  // 🟤 Cb - On Search
  const onSearch = useCallback(
    (myPhrase?: string) => {
      // convert filter value from map back to array
      const myFilters = convertSelectedFilterMapToArray(filters);
      const phrase = myPhrase ?? queryParam.phrase ?? '';
      const myBody = { ...apiBody, phrase, filters: myFilters };
      refetchDetail(myBody, { ...apiParams, page: 1 });
      setQueryParam({ ...queryParam, phrase, page: '1' });
    },
    [apiBody, apiParams, filters, queryParam, refetchDetail, setQueryParam]
  );
  // 🟤 Cb - Reset Search and Filter
  const resetSearch = useCallback(() => {
    const clearedFilters = clearSelectFilters(filters);
    const myFilters = convertSelectedFilterMapToArray(clearedFilters);
    const myBody = { ...apiBody, phrase: '', filters: myFilters };
    setFilters(clearedFilters);
    refetchDetail(myBody, { ...apiParams, page: 1 });
    setQueryParam({ ...queryParam, phrase: '', page: '1' });
  }, [apiBody, apiParams, filters, queryParam, refetchDetail, setQueryParam]);

  /**
   * Memo
   */
  const providerValues = useMemo(
    () => ({
      title,
      data: api.data,
      deletedProducts,
      filters,
      id,
      isEditingTitle,
      loading: api.loading,
      onSearch,
      queryParam,
      refetchDetail,
      resetSearch,
      saveModalOpen,
      setDeletedProducts,
      setFilters,
      setSaveModalOpen,
      setIsEditingTitle,
      setTitle,
      setUploadIds,
      setUploadOpen,
      setRegionalOpen,
      submitUploads,
      toggleDeleteProduct,
      updatePage,
      uploadIds,
      uploadOpen,
      regionalOpen
    }),
    [
      title,
      api.data,
      api.loading,
      deletedProducts,
      filters,
      id,
      isEditingTitle,
      onSearch,
      queryParam,
      refetchDetail,
      resetSearch,
      saveModalOpen,
      submitUploads,
      toggleDeleteProduct,
      updatePage,
      uploadIds,
      uploadOpen,
      regionalOpen
    ]
  );

  /**
   * Render
   */
  return (
    <CatalogContext.Provider value={providerValues}>
      {children}
    </CatalogContext.Provider>
  );
}

export default CatalogProvider;
