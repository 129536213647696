import clsx from 'clsx';

import { HeaderedContainerProps } from 'common/Container/types';
import { useUnsavedContext } from 'providers/UnsavedProvider';
import { BackIcon } from 'resources/icons';

/**
 * Component
 */
const HeaderedContainer = (props: HeaderedContainerProps) => {
  /**
   * Context
   */
  const { openModal, unsaved } = useUnsavedContext();

  /**
   * Render
   */
  return (
    <div
      className="bg-common-background h-screen overflow-y-scroll"
      data-testid={`${props['data-testid']}-container`}
    >
      <div className="bg-white w-full py-6 flex shadow-md">
        {Boolean(props.back) && (
          <span className="px-10 flex items-center">
            <button
              className="text-primary-1-100"
              type="button"
              data-testid={`${props['data-testid']}-back`}
              onClick={unsaved ? () => openModal(props.back!) : props.back}
            >
              <BackIcon />
            </button>
          </span>
        )}
        <span
          className="text-primary-1-100 w-full text-center flex-1 font-semibold text-4xl"
          data-testid={`${props['data-testid']}-header`}
        >
          {props.title}
        </span>
      </div>
      <div className={clsx('w-[calc(100%-80px)] my-6 mx-10', props.className)}>
        {props.children}
      </div>
    </div>
  );
};

export default HeaderedContainer;
