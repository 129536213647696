import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { WrapperProps } from '@reece/global-types';
import { noop } from 'lodash-es';
import { Maybe } from 'yup';

import DeleteCustomerModal from './DeleteCustomerModal';
import { ToastConfig, useToastContext } from 'providers/ToastProvider';
import { useApiCustomerDelete } from 'api/customer.api';
import { useNavigate } from 'react-router-dom';

/**
 * Types
 */
export type HomeContextType = {
  deletedCustomerId?: Maybe<string>;
  deleteModalOpen: boolean;
  setDeletedCustomerId: Dispatch<string>;
  toggleDeleteCustomerId: (customerId: Maybe<string>) => void;
  setDeleteModalOpen: Dispatch<boolean>;
  onLeaveDeleteButtonPress: () => void;
  onDeleteButtonPress?: () => Promise<void>;
};

/**
 * Config
 */
export const defaultHomeContext: HomeContextType = {
  deleteModalOpen: false,
  setDeletedCustomerId: noop,
  toggleDeleteCustomerId: noop,
  setDeleteModalOpen: noop,
  onLeaveDeleteButtonPress: noop
};

export const errorDeleteToastNotification: ToastConfig = {
  message: 'Unable to delete customer',
  kind: 'error'
};

/**
 * Context
 */
export const HomeContext = createContext(defaultHomeContext);
export const useHomeContext = () => useContext(HomeContext);

/**
 * Provider
 */
function HomeProvider({ children }: WrapperProps) {
  /**
   * Custom hooks
   */
  const navigate = useNavigate();

  /**
   * Context
   */
  const { toast } = useToastContext();

  /**
   * States
   */
  const [deletedCustomerId, setDeletedCustomerId] = useState<string>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  /**
   * API
   */
  const api = useApiCustomerDelete(deletedCustomerId, {
    onError: () => {
      toast(errorDeleteToastNotification);
    }
  });

  /**
   * Callbacks
   */
  // 🟤 Cb - Toggle delete customer by Id
  const toggleDeleteCustomerId = useCallback(
    (customerId: Maybe<string>) => {
      if (customerId) {
        setDeletedCustomerId(customerId);
        setDeleteModalOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletedCustomerId, deleteModalOpen]
  );
  // 🟤 Cb - leave button
  const onLeaveDeleteButtonPress = useCallback(
    () => {
      setDeletedCustomerId('');
      setDeleteModalOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletedCustomerId, deleteModalOpen]
  );
  // 🟤 Cb - delete button
  const onDeleteButtonPress = useCallback(
    async () => {
      const res = await api.call(deletedCustomerId);
      if (res?.data?.success) {
        setDeletedCustomerId('');
        setDeleteModalOpen(false);
        navigate('/', { replace: true });
        navigate(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletedCustomerId, deleteModalOpen]
  );

  /**
   * Memo
   */
  const providerValues = useMemo(
    () => ({
      deletedCustomerId,
      deleteModalOpen,
      setDeletedCustomerId,
      toggleDeleteCustomerId,
      setDeleteModalOpen,
      onLeaveDeleteButtonPress,
      onDeleteButtonPress
    }),
    [
      deletedCustomerId,
      deleteModalOpen,
      setDeletedCustomerId,
      toggleDeleteCustomerId,
      onLeaveDeleteButtonPress,
      onDeleteButtonPress
    ]
  );

  /**
   * Render
   */
  return (
    <HomeContext.Provider value={providerValues}>
      {children}
      <DeleteCustomerModal></DeleteCustomerModal>
    </HomeContext.Provider>
  );
}

export default HomeProvider;
