import { useMemo, useState } from 'react';

import {
  Customer,
  CustomerListParam,
  useApiCustomerList
} from 'api/customer.api';
import { HeaderedContainer } from 'common/Container';
import { SearchInput } from 'components/Input';
import { Table } from 'components/Table';
import CreateCustomer from 'pages/Home/CreateCustomerModal';
import { customerListTableConfig } from 'pages/Home/tableConfig';
import { useToastContext } from 'providers/ToastProvider';
import { configuration } from 'util/configurations';
import { Button } from 'components/Button';
import { useHomeContext } from './HomeProvider';

/**
 * Config
 */
const perPage = configuration.itemsPerPage;
const defaultParam: CustomerListParam = { page: 1, perPage, phrase: '' };

/**
 * Component
 */
function HomePage() {
  /**
   * Contexts
   */
  const { toast } = useToastContext();

  /**
   * Contexts
   */
  const { toggleDeleteCustomerId } = useHomeContext();

  /**
   * States
   */
  const [page, setPage] = useState(defaultParam.page);
  const [phrase, setPhrase] = useState(defaultParam.phrase);
  const [error, setError] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const helperText =
    error || noResults
      ? 'Enter a valid company name, bill-to account #, or home branch'
      : '';

  /**
   * Data
   */
  const param: CustomerListParam = { page, perPage, phrase };
  const customerListApi = useApiCustomerList(param, {
    onCompleted: ({ data }) => {
      setCustomers(data.customers);
      setPage(data.page);
    }
  });

  /**
   * Callback
   */
  // 🟤 Cb - API call
  const apiCall = async (apiParam: CustomerListParam) => {
    const res = await customerListApi.refetch(apiParam);
    if (!res) {
      toast({ message: 'There is an issue searching' });
      setError(true);
      return;
    }

    if (!res.data.totalItems) {
      setNoResults(true);
      return;
    }

    setError(false);
    setNoResults(false);
  };
  // 🟤 Cb - Search
  const onSearch = async (phrase: string) => {
    setPage(defaultParam.page);
    await apiCall({ ...param, phrase, page: 1 });
  };
  // 🟤 Cb - Page Change
  const onPageChange = async (page: number) =>
    await apiCall({ ...param, page });
  // 🟤 Cb - Reset
  const onReset = async () => {
    await apiCall(defaultParam);
    setError(false);
    setNoResults(false);
    setPage(defaultParam.page);
    setPhrase(defaultParam.phrase);
  };

  /**
   * Memo
   */
  const tableInstance = useMemo(
    () => customerListTableConfig(toggleDeleteCustomerId, customers),
    [toggleDeleteCustomerId, customers]
  );

  /**
   * Render
   */
  return (
    <HeaderedContainer title="Existing Punchout Customers" data-testid="home">
      <div className="flex">
        <div className="flex-1 max-w-[480px]">
          <SearchInput
            label="Search Customer:"
            type="search"
            placeholder="Input Company Name, Bill-To #, or Home Branch"
            loading={customerListApi.loading}
            value={phrase}
            setValue={setPhrase}
            onSearch={onSearch}
            helperText={helperText}
            status={helperText ? 'error' : 'neutral'}
            data-testid="home_search"
            sync
          />
        </div>
        <div className="pt-8 pl-4">
          <Button
            type="button"
            title="Clear All"
            disabled={customerListApi.loading}
            className=" text-primary-2-100"
            onClick={onReset}
            data-testid="home-reset-button"
          />
        </div>
        <div className="flex-1 flex justify-end">
          <CreateCustomer onSubmitted={customerListApi.refetch} />
        </div>
      </div>
      <Table
        id="customers"
        className="pt-8"
        noResultsMessage="No Customers Found!"
        loading={customerListApi.loading}
        table={tableInstance}
        data-testid="home_table"
        showItemCount
        showPagination
        itemCount={customerListApi.data?.totalItems}
        currentPage={page}
        pages={customerListApi.data?.totalPages || 1}
        onPageChange={onPageChange}
      />
    </HeaderedContainer>
  );
}

export default HomePage;
