import { Dispatch, useCallback, useMemo, useState } from 'react';

import { kebabCase } from 'lodash-es';

import { useApiProductSearchFilters } from 'api/product.api';
import { SelectFilter } from 'common/ProductFilters/types';
import {
  clearSelectFilters,
  convertSelectedFilterArrayToMap
} from 'common/ProductFilters/util';
import { useToastContext } from 'providers/ToastProvider';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Sidebar } from 'components/Modal';
import clsx from 'clsx';

/**
 * Types
 */
export type ProductFiltersProps = {
  className?: string;
  'data-testid'?: string;
  filters: SelectFilter[];
  loading: boolean;
  onSearch: () => void;
  setFilters: Dispatch<SelectFilter[]>;
};

/**
 * Component
 */
function ProductFilters(props: ProductFiltersProps) {
  /**
   * Contexts
   */
  const { toast } = useToastContext();
  /**
   * State
   */
  const [open, setOpen] = useState(false);
  const [enableFilterButton, setEnableFilterButton] = useState(true);

  // 🟣 API - Get filters
  const { loading } = useApiProductSearchFilters({
    onCompleted: ({ data }) =>
      props.setFilters(convertSelectedFilterArrayToMap(data.filters)),
    onError: (e) =>
      toast({
        message: 'Error: Filters failed to apply. Please try again.',
        kind: 'error',
        hasClose: true
      })
  });

  /**
   * Memos
   */
  // 🔵 Memo - Number of filters applied
  const counts = useMemo(() => {
    const listCounts = props.filters.map((filter) => {
      const filterKeysArray = Object.keys(filter.values);
      const selectedFilters = filterKeysArray.filter((k) => filter.values[k]);
      return selectedFilters.length;
    });
    setEnableFilterButton(true);
    return listCounts.reduce((prev, current) => prev + current, 0);
  }, [props.filters]);

  /**
   * Callback
   */
  // 🟤 Cb - Open Modal
  const openModal = () => setOpen(true);
  // 🟤 Cb - Close Modal
  const closeModal = () => {
    setOpen(false);
    toast({
      message: 'Selected filters were not applied',
      hasClose: true
    });
  };
  // 🟤 Cb - Search
  const onSearch = () => {
    closeModal();
    props.onSearch();
    toast({
      message: 'Filters applied successfully',
      kind: 'success',
      hasClose: true
    });
    setEnableFilterButton(false);
  };
  // 🟤 Cb - Clear Filters
  const clearFilters = useCallback(() => {
    const updatedFilters = clearSelectFilters(props.filters);
    props.setFilters(updatedFilters);
    closeModal();
    props.onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters]);
  // 🟤 Cb - Toggle Filters
  const toggleFilters = useCallback(
    (id: string, value: string) => {
      // Find the filter index
      const filterIndex = props.filters.findIndex((f) => f.id === id);
      // Clone filter for mutations
      const mutableFilters = [...props.filters];
      // Invert the value's boolean (selected state)
      const foundValue = mutableFilters[filterIndex].values[value];
      mutableFilters[filterIndex].values[value] = !foundValue;
      // Apply the changes
      props.setFilters(mutableFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.filters]
  );

  /**
   * Render
   */
  return (
    <>
      <Button
        type="button"
        title={counts ? ` Filters (${counts})` : 'Filters'}
        loading={loading || props.loading}
        className={clsx(
          'bg-white text-primary-1-100 border-primary-1-100 border-2',
          props.className
        )}
        onClick={openModal}
        data-testid={`${props['data-testid']}-filters-button`}
      />
      <Sidebar
        open={open}
        className="!w-[370px]"
        title="Filter Products"
        onClose={closeModal}
        data-testid={`${props['data-testid']}-filters`}
      >
        <div className="h-full flex flex-col">
          <div className="relative flex-1 max-h-[100%-100px]">
            <div className="absolute left-0 top-0 bottom-0 right-0 p-7 overflow-y-scroll">
              {props.filters.map((filter) => (
                <div className="py-2 px-2" key={filter.id}>
                  <h5
                    className="text-primary-1-100 text-xl font-medium my-2"
                    data-testid={`filter-${filter.id}`}
                  >
                    {filter.name}
                  </h5>
                  {Object.entries(filter.values).map(
                    ([value, selected], index) => (
                      <Checkbox
                        data-testid={`value-${kebabCase(value)}`}
                        checked={selected}
                        label={value}
                        key={`${filter.id}-${index}`}
                        className="my-2 text-secondary-2-100"
                        onChange={() => toggleFilters(filter.id, value)}
                      />
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white flex justify-center p-6 gap-4 drop-shadow-2xl h-[100px]">
            <Button
              type="button"
              title="Clear Filters"
              className="bg-white text-primary-1-100 border-primary-1-100 border-2"
              onClick={clearFilters}
              data-testid={`${props['data-testid']}-clear-filters-button`}
              disabled={!counts}
            />
            <Button
              type="button"
              title="Apply Filters"
              className="bg-primary-1-100 text-white border-primary-1-100 border-2"
              onClick={onSearch}
              data-testid={`${props['data-testid']}-apply-filters-button`}
              disabled={!counts || !enableFilterButton}
            />
          </div>
        </div>
      </Sidebar>
    </>
  );
}
export default ProductFilters;
