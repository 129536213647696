import { useCallback, useMemo, useState } from 'react';

import { useApiSaveCatalogProduct } from 'api/catalog.api';
import { Product } from 'api/product.api';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { ProductSearchQueryParams } from 'pages/ProductSearch';
import { reviewProductSearchTableConfig } from 'pages/ProductSearch/tableConfig';
import { ProductSearchIcon } from 'resources/icons';
import { configuration } from 'util/configurations';
import { useQueryParams } from 'util/hooks/useSearchParam';

/**
 * Types
 */
export type ProductSearchReviewProps = {
  loading: boolean;
  error: boolean;
  selectedProducts: Product[];
  setSelectedProducts: (filters: Product[]) => void;
  navigateBack: () => void;
};

/**
 * Config
 */
const { itemsPerPage } = configuration;

/**
 * Component
 */
function ProductSearchReview(props: ProductSearchReviewProps) {
  /**
   * Props
   */
  const { selectedProducts, setSelectedProducts } = props;
  const maxPages = Math.ceil(selectedProducts.length / itemsPerPage);

  /**
   * Custom hooks
   */
  const [{ cid }] = useQueryParams<ProductSearchQueryParams>();

  /**
   * States
   */
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [tableItem, setTableItems] = useState<Product[]>([]);

  /**
   * API
   */
  const saveCatalogProductApi = useApiSaveCatalogProduct(cid);

  /**
   * Callbacks
   */
  // 🟤 Cb - Update items to be displayed in the table page
  const updateTableItems = useCallback(
    (page: number, selectedProductsAlt?: Product[]) => {
      // Starting point to pull the array for `tableItems`
      const position = (page - 1) * itemsPerPage;
      // Cloning either the optional override `selectedProductsAlt` or the parent `selectedProducts` by default
      const source = [...(selectedProductsAlt ?? selectedProducts)];
      // Pull the chunk of data from the source
      const splicedItems = source.splice(position, itemsPerPage);
      // Apply
      setPage(Math.max(page, 1));
      setTableItems(splicedItems);
    },
    [selectedProducts]
  );
  // 🟤 Cb - Delete product
  const deleteSelectedProduct = useCallback(
    (remove: Product) => {
      // Filter out the product it is deleting
      const newProducts = selectedProducts.filter(({ id }) => id !== remove.id);
      // Calculate the correct page in case the last item of the last page is deleted
      const maxPages = Math.ceil(newProducts.length / itemsPerPage);
      const goToPage = Math.min(page, maxPages);
      // Update changes to parent
      setSelectedProducts(newProducts);
      // Refresh
      updateTableItems(goToPage, newProducts);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, selectedProducts, updateTableItems]
  );
  // 🟤 Cb - Open modal
  const openModal = () => {
    updateTableItems(page);
    setOpen(true);
  };
  // 🟤 Cb - Close modal
  const closeModal = () => setOpen(false);
  // 🟤 Cb - Save API
  const saveCatalogProduct = async () => {
    // Map out the selectedProducts only to the Id
    const productIds = selectedProducts.map(({ id }) => id);
    // API call
    const res = await saveCatalogProductApi.call(productIds);
    // API fail
    if (!res?.data.success) {
      res?.data.message && console.error(res?.data.message);
      return;
    }
    // Navigate back to Catalog when done
    props.navigateBack();
  };

  /**
   * Memo (table)
   */
  // 🔵 Memo - Table Instance
  const tableInstance = useMemo(
    () => reviewProductSearchTableConfig(tableItem, deleteSelectedProduct),
    [deleteSelectedProduct, tableItem]
  );

  /**
   * Render
   */
  return (
    <>
      <Button
        type="button"
        title="Review All Changes"
        icon={<ProductSearchIcon />}
        loading={props.loading}
        iconPosition="left"
        disabled={
          props.loading || props.error || !props.selectedProducts.length
        }
        className="bg-support-1-100 text-white border-support-1-100 border-2"
        onClick={openModal}
        data-testid="product_search-review-button"
      />
      <Modal
        open={open}
        title="Review All Changes"
        className="w-[1094px]"
        onClose={closeModal}
        disableClose={saveCatalogProductApi.loading}
        data-testid="product_search-review"
      >
        <div className="text-primary-3-100 mt-4 mb-8">
          These are you recent changes to the catalog. Would you like to save
          these changes?
        </div>
        <Table
          id="review-product-search"
          table={tableInstance}
          showPagination
          showItemCount
          itemCount={selectedProducts.length}
          pages={maxPages}
          currentPage={page}
          loading={saveCatalogProductApi.loading}
          onPageChange={updateTableItems}
          noResultsMessage="No products selected"
          data-testid="product_search-review-table"
        />
        <div className="flex justify-end gap-6 mt-4">
          <Button
            type="button"
            title="Back"
            className="bg-white text-primary-2-100 underline"
            onClick={closeModal}
            disabled={saveCatalogProductApi.loading}
            data-testid="product_search-review-leave-button"
          />
          <Button
            type="button"
            title="Save Changes"
            disabled={!cid}
            loading={saveCatalogProductApi.loading}
            className="bg-primary-1-100 text-white font-medium"
            onClick={saveCatalogProduct}
            data-testid="product_search-review-save-button"
          />
        </div>
      </Modal>
    </>
  );
}

export default ProductSearchReview;
