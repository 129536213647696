import { useCallback, useState } from 'react';

import {
  Customer,
  useApiCustomerAdd,
  useApiCustomerAddV2,
  useApiCustomerSearch
} from 'api/customer.api';
import { Button } from 'components/Button';
import { Input, InputLabel, SearchInput } from 'components/Input';
import { Modal } from 'components/Modal';
import { AddIcon } from 'resources/icons';
import CreateCustomerUpload from 'pages/Home/CreateCustomerUpload';
import { UploadedFile } from 'components/FileUpload';
import { isUndefined } from 'lodash-es';
import { useToastContext } from 'providers/ToastProvider';
import { configuration } from 'util/configurations';
import {
  ProcurementSystem,
  useApiProcurementSystemList
} from 'api/catalog.api';
import { PunchoutReusableContent } from 'util/contents';

export type CreateCustomerProps = {
  disabled?: boolean;
  onSubmitted?: () => void;
  test?: string;
};

/**
 * Config
 */
const defaultSystems: ProcurementSystem[] = [
  { name: PunchoutReusableContent.EMPTY }
];

function CreateCustomer(props: CreateCustomerProps) {
  /**
   * Context
   */
  const { toast } = useToastContext();

  /**
   * State
   */
  const [open, setOpen] = useState(false);
  const [foundCustomer, setFoundCustomer] = useState<Customer>();
  const [error, setError] = useState(false);
  const [isUpload, setIsUpload] = useState<boolean>();
  const [uploads, setUploads] = useState<UploadedFile[]>([]);
  const [procurementSystems, setProcurementSystems] = useState(defaultSystems);
  const [procurementSystem, setSelectedSystem] = useState('');

  /**
   * API
   */
  // 🟣 API - Procurement System
  const procurementSystemsApi = useApiProcurementSystemList({
    onCompleted: ({ data: { procurementSystems: sys } }) => {
      const filteredSystems = sys.filter(({ name }) => Boolean(name));
      setProcurementSystems(filteredSystems);
      setSelectedSystem(sys[0].name);
    }
  });
  // 🟣 API - Customer search
  const searchCustomerApi = useApiCustomerSearch();
  // 🟣 API - Add customer after search
  const addCustomerV1Api = useApiCustomerAdd();
  const addCustomerV2Api = useApiCustomerAddV2();

  /**
   * Consts
   */
  const loading =
    procurementSystemsApi.loading ||
    searchCustomerApi.loading ||
    addCustomerV1Api.loading ||
    addCustomerV2Api.loading;
  const isUploadOptionsSelected =
    !configuration.useLegacyAddCustomer &&
    (isUndefined(isUpload) || (isUpload && !uploads.length));
  const disableSubmit =
    props.disabled ||
    !foundCustomer?.branchId ||
    !foundCustomer?.branchName ||
    loading ||
    isUploadOptionsSelected;

  /**
   * Callbacks
   */
  // 🟤 CB - Close Modal
  const onClose = () => setOpen(false);
  // 🟤 CB - Search call
  const onCustomerSearch = async (value: string) => {
    const res = await searchCustomerApi.call(value);
    setError(res?.status !== 200);
    res?.data?.erpId
      ? setFoundCustomer(res?.data)
      : setFoundCustomer(undefined);
  };
  // 🟤 CB - Add call
  const onAddNewCustomer = useCallback(async () => {
    const uploadIds = uploads.map(({ id }) => id ?? '');
    // adding "!" since this cannot be called when foundCustomer is undefined, which disables the element callling this
    const res = configuration.useLegacyAddCustomer
      ? await addCustomerV1Api.call({ ...foundCustomer! })
      : await addCustomerV2Api.call({
          customer: foundCustomer!,
          uploadIds,
          procurementSystem
        });
    if (res?.status !== 200) {
      toast({
        message: PunchoutReusableContent.DEFAULT_TOAST_ERROR,
        kind: 'error'
      }); // Replace MESSAGE
      return;
    }
    setFoundCustomer(undefined);
    props.onSubmitted?.();
    onClose();
  }, [
    addCustomerV1Api,
    addCustomerV2Api,
    foundCustomer,
    props,
    toast,
    uploads,
    procurementSystem
  ]);

  /**
   * Render
   */
  return (
    <>
      <Button
        type="button"
        title="Add New Customer"
        className="ml-6 mt-8 mb-5 bg-primary-1-100 text-white"
        disabled={props.disabled}
        iconPosition="left"
        icon={<AddIcon />}
        onClick={() => setOpen(true)}
        data-testid="create_customer-open-button"
      />
      <Modal
        className="max-w-[786px] px-10 py-8"
        open={open}
        onClose={onClose}
        title="Add New Customer"
        data-testid="create_customer-modal"
      >
        <div className="mt-4 flex flex-col gap-6">
          <div className="w-[480px]">
            <SearchInput
              label="Search Customer "
              type="search"
              required
              placeholder="Input Bill-To #"
              labelClassName="font-medium text-xl"
              onSearch={onCustomerSearch}
              status={error ? 'error' : 'neutral'}
              data-testid="create_customer-search-input"
              helperText={error ? 'Enter a valid bill-to account #' : ' '}
              disabled={loading}
            />
          </div>
          <div className="flex gap-4">
            <div className="flex-1">
              <Input
                label="Customer Name"
                disabled
                className="w-[480px]"
                labelClassName="font-medium text-xl"
                value={foundCustomer?.name ?? ''}
                data-testid="create_customer-name-input"
              />
            </div>
            <div className="flex-1">
              <Input
                label="Home Branch"
                disabled
                labelClassName="font-medium text-xl"
                value={foundCustomer?.branchId ?? ''}
                data-testid="create_customer-branch-input"
              />
            </div>
          </div>
          {configuration.useLegacyAddCustomer ? (
            <div className="pb-8">
              <InputLabel
                label="Assigned Regions and Territories: "
                className="font-medium text-xl"
              />
              <p className="py-4">
                Regions are automatically assigned based on the customer’s home
                branch. Based on the regions and territories assigned, an
                initial draft catalog will be created.
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-4 mt-4">
                <InputLabel
                  label="Select product catalog procurement system: "
                  required
                  className="font-medium text-xl"
                />
                <span className="text-secondary-2-100 text-base">
                  Please select which <strong>procurement system</strong> you
                  would like to send the changes to:
                </span>
                <form className="flex gap-4">
                  {procurementSystems.map((system, index) => (
                    <div key={system.name}>
                      <label>
                        <input
                          type="radio"
                          value={system.name}
                          disabled={!foundCustomer}
                          checked={procurementSystem === system.name}
                          onChange={() => setSelectedSystem(system.name)}
                          className="mx-2"
                          data-testid={`catalog_save-radio${index}`}
                        />
                        {system.name}
                      </label>
                    </div>
                  ))}
                </form>
              </div>
              <CreateCustomerUpload
                isUpload={isUpload}
                loading={loading}
                setIsUpload={setIsUpload}
                setUploads={setUploads}
                uploads={uploads}
                disabled={!foundCustomer}
              />
            </>
          )}
          <div className="flex justify-end">
            <Button
              type="button"
              title="Add New Customer"
              className="bg-primary-1-100 text-white"
              disabled={disableSubmit}
              iconPosition="left"
              icon={<AddIcon />}
              onClick={onAddNewCustomer}
              data-testid="create_customer-submit-button"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
export default CreateCustomer;
