import { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Product,
  ProductSearchParam,
  ProductSearchRequest,
  useApiProductSearch
} from 'api/product.api';
import ProductFilters from 'common/ProductFilters';
import { SelectFilter } from 'common/ProductFilters/types';
import {
  clearSelectFilters,
  convertSelectedFilterMapToArray
} from 'common/ProductFilters/util';
import { SearchInput } from 'components/Input';
import { Table } from 'components/Table';
import ProductSearchReview from 'pages/ProductSearch/ProductSearchReview';
import { productSearchTableConfig } from 'pages/ProductSearch/tableConfig';
import useUnsavedContextConfig from 'providers/hooks/useUnsavedContextConfig';
import {
  UnsavedModalDisplay,
  useUnsavedContext
} from 'providers/UnsavedProvider';
import { BackIcon } from 'resources/icons';
import { PunchoutReusableContent } from 'util/contents';
import { useQueryParams } from 'util/hooks/useSearchParam';
import { configuration } from 'util/configurations';
import { Button } from 'components/Button';

/**
 * Types
 */
export type ProductSearchQueryParams = {
  cid?: string;
  catalogName?: string;
};

/**
 * Config
 */
const perPage = configuration.itemsPerPage;
const texts = {
  title: 'All Products - Search',
  error: 'Enter a valid product name, description, part #, or MFR#',
  productsSelected: 'product(s) selected',
  searchPlaceholder: 'Input Product Name, Description, Part #, or MFR #',
  searchTitle: 'Search All Products:',
  tableNoProducts:
    'No products shown. Please search for a product by product name, description, part #, MFR# OR use the filters.'
};
const unsavedModalConfig: UnsavedModalDisplay = {
  save: 'Return to changes',
  body: (
    <>
      <p>There are unsaved changes to this product search.</p>
      <p className="mt-4">
        Are you sure you want to leave this page without saving changes?
      </p>
    </>
  )
};

/**
 * Component
 */
function ProductSearch() {
  /**
   * Custom hooks
   */
  const navigate = useNavigate();
  const [{ cid, catalogName }] = useQueryParams<ProductSearchQueryParams>();

  /**
   * Context
   */
  const { openModal, setUnsaved, unsaved } = useUnsavedContext();

  /**
   * States
   */
  const [products, setProducts] = useState<Product[]>([]);
  const [phrase, setPhrase] = useState('');
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState<SelectFilter[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loadAll, setLoadAll] = useState(true);
  const helperText = error ? texts.error : '';
  const searchParams: ProductSearchParam = { perPage, page };

  /**
   * API
   */
  // 🟣 API - Search product
  const productSearchApi = useApiProductSearch();

  /**
   * Callback
   */
  // 🟤 Cb - API call
  const apiCall = async (
    searchParams: ProductSearchParam,
    myPhrase?: string
  ) => {
    // convert filter value from map back to array
    const myFilters = convertSelectedFilterMapToArray(filters);
    // in case a phrase and filters isn't set, return to prevent a search (considered as empty search)
    if (!phrase && !myFilters && !loadAll) {
      return;
    }
    // prepare request body and param
    const request: ProductSearchRequest = {
      filters: myFilters,
      phrase: myPhrase ?? ''
    };
    // call
    const res = await productSearchApi.call(searchParams, request);
    // error
    if (!res?.data) {
      setError(true);
      return;
    }
    // populate
    setError(false);
    setTotalPage(res.data.totalPages || 1);
    setTotalItems(res.data.totalItems);
    setProducts(res.data.products);
  };

  // 🟤 Cb - onSearch
  const onSearch = async () => {
    setPage(1);
    await apiCall({ ...searchParams, page: 1 }, phrase);
  };
  // 🟤 Cb - onPageChange
  const onPageChange = async (page: number) => {
    setPage(page);
    await apiCall({ ...searchParams, page }, phrase);
  };
  // 🟤 Cb - Table Checkbox Toggle
  const updateSelectedProducts = useCallback(
    (product: Product) => {
      const found = selectedProducts.some(({ id }) => id === product.id);
      // Add selected product to list
      if (!found) {
        setSelectedProducts([...selectedProducts, product]);
        setUnsaved(true);
        return;
      }
      // Remove seleced product from list
      const filtered = selectedProducts.filter(({ id }) => id !== product.id);
      setSelectedProducts(filtered);
      setUnsaved(Boolean(filtered.length));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProducts]
  );
  // 🟤 Cb - Navigate back
  const navigateBack = () => navigate(cid ? `/catalog/detail/${cid}` : '/');
  // 🟤 Cb - Reset search
  const resetSearch = () => {
    const resetFilters = clearSelectFilters(filters);
    setFilters(resetFilters);
    setPhrase('');
    setPage(1);
    apiCall({ ...searchParams, page: 1 }, undefined);
  };

  /**
   * Memo
   */
  // 🔵 Memo - Data model used for table
  const productSearchProduct = useMemo(
    () =>
      products.map((product) => ({
        ...product,
        selected: selectedProducts.some(({ id }) => id === product.id)
      })),
    [products, selectedProducts]
  );
  // 🔵 Memo - Table Instance
  const tableInstance = useMemo(
    () =>
      productSearchTableConfig(productSearchProduct, updateSelectedProducts),
    [productSearchProduct, updateSelectedProducts]
  );

  /**
   * Special Hook
   */
  useUnsavedContextConfig({ modalDisplay: unsavedModalConfig });

  if (loadAll) {
    resetSearch();
    setLoadAll(false);
  }

  /**
   * Render
   */
  return (
    <div
      className="bg-common-background h-screen overflow-y-scroll"
      data-testid="product_search-container"
    >
      <div className="bg-white w-full py-6 flex shadow-md">
        <span className="px-10 flex items-center">
          <button
            className="text-primary-1-100"
            type="button"
            data-testid="product_search-back"
            onClick={unsaved ? () => openModal(navigateBack) : navigateBack}
          >
            <BackIcon />
          </button>
        </span>
        <span
          className="text-primary-1-100 w-full text-center flex-1 font-semibold text-4xl"
          data-testid="product_search-header"
        >
          {texts.title}
        </span>
      </div>
      <div className="w-[calc(100%-80px)] my-6 mx-10">
        <h5 className="text-xl text-primary-3-100">
          You are adding to Catalog:{' '}
          <span className="text-primary-1-100 font-semibold">
            {catalogName ?? PunchoutReusableContent.EMPTY}
          </span>
        </h5>
        <div className="flex mt-4 mb-6">
          <div className="flex-1 max-w-[480px]">
            <SearchInput
              label={texts.searchTitle}
              type="search"
              sync
              value={phrase}
              setValue={setPhrase}
              loading={productSearchApi.loading}
              placeholder={texts.searchPlaceholder}
              onSearch={onSearch}
              helperText={helperText}
              status={helperText ? 'error' : 'neutral'}
              data-testid="product_search-search"
            />
          </div>
          <div className="flex-1 flex justify-between items-center pt-3 pl-8">
            <div>
              <ProductFilters
                filters={filters}
                onSearch={onSearch}
                setFilters={setFilters}
                loading={productSearchApi.loading}
                data-testid="product_search"
              />
              <Button
                type="button"
                title="Clear All"
                disabled={productSearchApi.loading}
                className=" text-primary-2-100 ml-6"
                onClick={resetSearch}
                data-testid="product_search-reset-button"
              />
            </div>
            <ProductSearchReview
              loading={productSearchApi.loading}
              error={error}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              navigateBack={navigateBack}
            />
          </div>
        </div>
        {/* PLACEHOLDER STYLING */}
        <div className="text-support-2-100 font-semibold text-xl mb-4">
          {selectedProducts.length} {texts.productsSelected}
        </div>
        <Table
          id="product-search"
          noResultsMessage={texts.tableNoProducts}
          loading={productSearchApi.loading}
          table={tableInstance}
          data-testid="product_search-table"
          showItemCount
          showPagination
          itemCount={totalItems}
          currentPage={page}
          pages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
export default ProductSearch;
