import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  PostCatalogTitleRequest,
  useApiPostCatalogTitle
} from 'api/catalog.api';
import Input from 'components/Input/Input';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import { EditIcon } from 'resources/icons';

/**
 * Component
 */
function CatalogTitle() {
  /**
   * Props
   */
  const { isEditingTitle, setIsEditingTitle, setTitle, id, uploadIds, title } =
    useCatalogContext();

  /**
   * API
   */
  const api = useApiPostCatalogTitle(id);

  /**
   * Callbacks
   */
  // 🟤 Cb - Switch edit mode
  const toggleEditingMode = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isEditingTitle) {
      formik.handleSubmit();
    } else {
      formik.setFieldValue('name', title);
      setIsEditingTitle(true);
    }
  };
  // 🟤 Cb - Form Submit
  const onSubmit = (values: PostCatalogTitleRequest) => {
    if (id && !uploadIds.length) {
      api.call(values);
    }
    setTitle(values.name!);
    setIsEditingTitle(false);
  };
  // 🟤 Cb - Input Blur
  const onBlur = (e: React.FocusEvent) => {
    formik.handleBlur(e);
    formik.handleSubmit();
  };

  /**
   * Form
   */
  const formik = useFormik({
    initialValues: { name: title },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Catalog name must contain more than one character')
        .min(2, 'Catalog name must contain more than one character')
        .trim()
        .matches(/\S+/, 'Catalog name must contain more than one character')
    }),
    onSubmit
  });

  /**
   * Render
   */
  return (
    <form
      data-testid="catalog_title-form"
      onSubmit={formik.handleSubmit}
      className="flex flex-row"
    >
      {isEditingTitle ? (
        <Input
          id="name"
          name="name"
          data-testid="catalog_title-input"
          value={formik.values.name}
          status={formik.errors.name ? 'error' : 'neutral'}
          helperText={
            formik.touched.name && formik.errors.name ? formik.errors.name : ''
          }
          onChange={formik.handleChange}
          onBlur={onBlur}
          className="text-black font-semibold text-3xl ml-5"
        />
      ) : (
        <h3
          className="text-black font-semibold text-3xl ml-5 cursor-pointer"
          data-testid="catalog_title-name"
          onClick={toggleEditingMode}
        >
          {title}
        </h3>
      )}
      <button
        type={!isEditingTitle ? 'submit' : 'button'}
        className="text-primary-1-100 ml-4"
        data-testid="catalog_title-edit"
        onClick={toggleEditingMode}
      >
        <EditIcon />
      </button>
    </form>
  );
}

export default CatalogTitle;
