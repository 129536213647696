import { useEffect, useMemo } from 'react';

import { noop } from 'lodash-es';

import {
  defaultUnsavedModalDisplay,
  UnsavedModalDisplay,
  useUnsavedContext
} from 'providers/UnsavedProvider';

/**
 * Types
 */
export type UseUnsavedContextConfigProps = {
  onSave?: () => void;
  modalDisplay: UnsavedModalDisplay;
};

/**
 * Hook
 * @description 🔴 IMPORTANT 🔴 - `onSave` MUST be memo'ed using `useCallback` to prevent infinite re-renders
 */
function useUnsavedContextConfig(props: UseUnsavedContextConfigProps) {
  /**
   * Contexts
   */
  const { setModalDisplay, setOnSave } = useUnsavedContext();

  /**
   * Memos
   */
  const modalDisplay = useMemo(
    () => ({
      ...defaultUnsavedModalDisplay,
      ...props.modalDisplay
    }),
    [props.modalDisplay]
  );

  /**
   * Effects
   */
  // 🟡 Effect - Apply values to context
  useEffect(() => {
    setOnSave(props.onSave ?? noop);
    setModalDisplay(modalDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onSave, props.modalDisplay]);
}

export default useUnsavedContextConfig;
