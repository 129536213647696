import { DataMap } from '@reece/global-types';
import { Filter } from 'api/product.api';
import { SelectFilter } from 'common/ProductFilters/types';

// Used to convert `{ [key: string]: boolean }` into `string[]` only if it's true
export function convertMapToArray(input: DataMap<boolean>) {
  const entries = Object.entries(input);
  const filteredEntries = entries.filter(([_, isSelected]) => isSelected);
  const converted = filteredEntries.map(([filter]) => filter);
  return converted;
}

// Convert `SelectFilters` `Filter`
export function convertSelectedFilterMapToArray(input: SelectFilter[]) {
  return input.map((filter) => ({
    ...filter,
    values: [...convertMapToArray(filter.values)]
  }));
}

// Convert `Filter` into `SelectFilters`
export function convertSelectedFilterArrayToMap(input: Filter[]) {
  return input.map((filter) => {
    const values: DataMap<boolean> = {};
    filter.values.forEach((value) => (values[value] = false));
    return { ...filter, values } satisfies SelectFilter;
  }) as SelectFilter[];
}

// Logics to clear SelectFilter[]
export function clearSelectFilters(input: SelectFilter[]) {
  return input.map((filter) => {
    // Set all keys in the data map to false
    Object.keys(filter.values).forEach((k) => (filter.values[k] = false));
    return filter;
  });
}
