import { useCallback, useEffect, useMemo, useState } from 'react';

import { ProductCatalog, useApiDeleteCatalogProduct } from 'api/catalog.api';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import { catalogDeleteTableConfig } from 'components/FileUpload/tableConfig';
import { useToastContext } from 'providers/ToastProvider';
import { useUnsavedContext } from 'providers/UnsavedProvider';
import { configuration } from 'util/configurations';

/**
 * Config
 */
const { itemsPerPage } = configuration;
//
/**
 * Component
 */
function SaveDeleteModal() {
  /**
   * Context
   */
  const {
    deletedProducts,
    refetchDetail,
    saveModalOpen,
    setDeletedProducts,
    setSaveModalOpen
  } = useCatalogContext();
  const { setUnsaved } = useUnsavedContext();
  const { toast } = useToastContext();
  const maxPages = Math.ceil(deletedProducts.length / itemsPerPage);

  /**
   * States
   */
  const [page, setPage] = useState(1);
  const [tableItem, setTableItems] = useState<ProductCatalog[]>([]);

  /**
   * API
   */
  const deleteProductApi = useApiDeleteCatalogProduct();

  /**
   * Callbacks
   */
  // 🟤 Cb - Close modal
  const closeModal = () => setSaveModalOpen(false);
  // 🟤 Cb - Update items to be displayed in the table page
  const updateTableItems = useCallback(
    (page: number, override?: ProductCatalog[]) => {
      // Starting point to pull the array for `tableItems`
      const position = (page - 1) * itemsPerPage;
      // Cloning either the optional override `override` or the parent `deletedProducts` by default
      const source = [...(override ?? deletedProducts)];
      // Pull the chunk of data from the source
      const splicedItems = source.splice(position, itemsPerPage);
      // Apply
      setPage(page || 1);
      setTableItems(splicedItems);
    },
    [deletedProducts]
  );
  // 🟤 Cb - Delete product
  const deleteSelectedProduct = useCallback(
    (remove: ProductCatalog) => {
      // Filter out the product it is deleting
      const newDeletes = deletedProducts.filter(({ id }) => id !== remove.id);
      // Calculate the correct page in case the last item of the last page is deleted
      const maxPages = Math.ceil(newDeletes.length / itemsPerPage);
      const goToPage = Math.min(page, maxPages);
      // Update changes to parent and unsaved changes
      setDeletedProducts(newDeletes);
      setUnsaved(Boolean(newDeletes.length));
      // Close if it's 0
      !newDeletes.length && closeModal();
      // Refresh
      updateTableItems(goToPage, newDeletes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletedProducts, page, updateTableItems]
  );
  // 🟤 Cb - Save API
  const saveCatalogProduct = async () => {
    // Map out the deletedProducts only to the Id
    const catalogProductIds = deletedProducts.map(({ id }) => id);
    // API call
    const res = await deleteProductApi.call({ catalogProductIds });
    // API fail
    if (!res?.data.success) {
      toast({ kind: 'error', message: 'Product deletion fail!', timer: 3000 });
      return;
    }
    // Set unsaved as false
    setDeletedProducts([]);
    setUnsaved(false);
    closeModal();
    refetchDetail({}, { page: 1 });
  };

  /**
   * Memo (table)
   */
  // 🔵 Memo - Table Instance
  const tableInstance = useMemo(
    () => catalogDeleteTableConfig(tableItem, deleteSelectedProduct),
    [deleteSelectedProduct, tableItem]
  );

  /**
   * Effects
   */
  // 🟡 Effect - Automatically adjust table data when opened
  useEffect(
    () => {
      saveModalOpen && updateTableItems(1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveModalOpen]
  );

  /**
   * Render
   */
  return (
    <Modal
      open={saveModalOpen}
      title="Review All Changes"
      className="w-[1094px]"
      onClose={closeModal}
      disableClose={deleteProductApi.loading}
      data-testid="catalog_delete"
    >
      <div className="text-primary-3-100 mt-4 mb-8">
        These are you recent changes to the catalog. Would you like to save
        these changes?
      </div>
      <Table
        id="review-catalog-product-delete"
        table={tableInstance}
        showPagination
        showItemCount
        itemCount={deletedProducts.length}
        pages={maxPages}
        currentPage={page}
        loading={deleteProductApi.loading}
        onPageChange={updateTableItems}
        noResultsMessage="No products selected"
        data-testid="catalog_delete-review-table"
      />
      <div className="flex justify-end gap-6 mt-4">
        <Button
          type="button"
          title="Back"
          className="bg-white text-primary-2-100 underline"
          disabled={deleteProductApi.loading}
          onClick={closeModal}
          data-testid="catalog_delete-back-button"
        />
        <Button
          type="button"
          title="Save Changes"
          className="bg-primary-1-100 text-white font-medium"
          loading={deleteProductApi.loading}
          onClick={saveCatalogProduct}
          data-testid="catalog_delete-save-button"
        />
      </div>
    </Modal>
  );
}

export default SaveDeleteModal;
