import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Container } from 'common/Container';
import ProductFilters from 'common/ProductFilters';
import { Button } from 'components/Button';
import { SearchInput } from 'components/Input';
import { Table } from 'components/Table';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import CatalogSave from 'pages/Catalog/CatalogSave';
import CatalogTitle from 'pages/Catalog/CatalogTitle';
import EditActionsMenu from 'pages/Catalog/EditActionsMenu';
import { catalogListTableConfig } from 'components/FileUpload/tableConfig';
import UploadModal from 'pages/Catalog/UploadModal';
import RegionalModal from 'pages/Catalog/RegionalProductsModal';
import useUnsavedContextConfig from 'providers/hooks/useUnsavedContextConfig';
import {
  UnsavedModalDisplay,
  useUnsavedContext
} from 'providers/UnsavedProvider';
import { BackIcon } from 'resources/icons';
import { PunchoutReusableContent } from 'util/contents';

/**
 * Config
 */
const catalogUnsavedModalDisplayConfig: UnsavedModalDisplay = {
  save: 'Return to catalog',
  body: (
    <>
      <p>There are unsaved changes to this catalog.</p>
      <p className="mt-4">
        Are you sure you want to leave this page without saving changes?
      </p>
    </>
  )
};

/**
 * Component
 */
function CatalogPage() {
  /**
   * Custom hooks
   */
  const navigate = useNavigate();

  /**
   * Contexts
   */
  const {
    data,
    deletedProducts,
    filters,
    loading,
    onSearch,
    queryParam,
    resetSearch,
    setFilters,
    toggleDeleteProduct,
    updatePage
  } = useCatalogContext();
  const { openModal, unsaved } = useUnsavedContext();

  /**
   * Memo
   */
  // 🔵 Memo - Data model used for table
  const tableProducts = useMemo(
    () =>
      (data?.results ?? []).map((product) => ({
        ...product,
        selected: deletedProducts.some(({ id }) => id === product.id)
      })),
    [data?.results, deletedProducts]
  );
  // 🔵 Memo - Table Instance
  const tableInstance = useMemo(
    () => catalogListTableConfig(tableProducts, toggleDeleteProduct),
    [toggleDeleteProduct, tableProducts]
  );

  /**
   * Callbacks
   */
  const goBack = () =>
    navigate(`/customer/detail/${data?.customer?.id || queryParam.cid}`);

  /**
   * Special Hook
   */
  useUnsavedContextConfig({ modalDisplay: catalogUnsavedModalDisplayConfig });

  /**
   * Render
   */
  return (
    <Container data-testid="catalog_container">
      {/* HEADER */}
      <div className="flex mb-8">
        <div className="flex items-center grow">
          <button
            className="text-primary-1-100"
            type="button"
            data-testid="catalog_back"
            onClick={unsaved ? () => openModal(goBack) : goBack}
          >
            <BackIcon />
          </button>
          <CatalogTitle />
          <h4
            className="text-primary-3-80 font-normal text-2xl ml-4"
            data-testid="catalog-product-count"
          >
            {`(${data?.totalItems ?? 0} Items)`}
          </h4>
        </div>
        <div className="flex flex-col text-primary-3-100 text-right">
          <h5 className="text-xl font-medium" data-testid="catalog-customer">
            {data?.customer?.name ??
              queryParam.customerName ??
              PunchoutReusableContent.EMPTY}
          </h5>
          <span className="text-base" data-testid="catalog-customer-id">
            {data?.customer?.customerId ??
              queryParam.customerId ??
              PunchoutReusableContent.EMPTY}
          </span>
          <span data-testid="catalog-procurement-system">
            {'Procurement: '}
            <span
              className="text-primary-2-100 font-semibold"
              data-testid="catalog-procurement-system-name"
            >
              {data?.catalog?.procSystem ?? PunchoutReusableContent.EMPTY}
            </span>
          </span>
        </div>
      </div>
      {/* Action Bar */}
      <div className="flex">
        <div className="flex-1">
          <SearchInput
            sync
            label="Search Products within Catalog:"
            type="search"
            loading={loading}
            value={queryParam.phrase}
            placeholder="Input Product Name, Description, Part #, or MFR#..."
            onSearch={onSearch}
            data-testid="catalog-search"
            helperText={
              !data?.totalItems && !loading
                ? 'Enter a valid product name, description, part #, or MFR#'
                : ' '
            }
            status={!data?.totalItems && !loading ? 'error' : 'neutral'}
          />
        </div>
        <div>
          <ProductFilters
            filters={filters}
            onSearch={onSearch}
            setFilters={setFilters}
            loading={loading}
            className="ml-6 mt-[30px]"
            data-testid="catalog"
          />
        </div>
        <div className="pt-8 pl-4 flex-1">
          <Button
            type="button"
            title="Clear All"
            disabled={loading}
            className=" text-primary-2-100"
            onClick={resetSearch}
            data-testid="catalog-reset-button"
          />
        </div>
        <div className="flex flex-col">
          <EditActionsMenu />
          <CatalogSave />
        </div>
      </div>
      {/* Table */}
      <Table
        id="catalog"
        className="w-full"
        noResultsMessage="No products found in catalog. Pease edit the catalog to upload file or add products."
        loading={loading}
        table={tableInstance}
        data-testid="catalog-table"
        showItemCount
        itemCount={data?.totalItems}
        showPagination
        currentPage={data?.page}
        pages={data?.totalPages || 1}
        onPageChange={updatePage}
      />
      <UploadModal />
      <RegionalModal />
    </Container>
  );
}
export default CatalogPage;
