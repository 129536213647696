import { APIOptions } from 'api/hooks/useApiBase';
import { useApiMutation } from 'api/hooks/useApiMutation';
import { useApiOnMount, UseAPIOnMountProps } from 'api/hooks/useApiOnMount';
import { generateQueryParam } from 'util/generateQueryParam';
import { Maybe } from 'yup';

/**
 * Types
 */
// Data
export type Product = {
  id: string;
  name: Maybe<string>;
  description: Maybe<string>;
  imageFullSize: Maybe<string>;
  partNumber: Maybe<string>;
  manufacturer: Maybe<string>;
  categoryLevel1Name: Maybe<string>;
  categoryLevel2Name: Maybe<string>;
  categoryLevel3Name: Maybe<string>;
  unspsc: Maybe<string>;
  imageThumb: Maybe<string>;
  manufacturerPartNumber: Maybe<string>;
  deliveryInDays: Maybe<number>;
  maxSyncDatetime: Maybe<string>;
};
export type Filter = {
  id: string;
  name: string;
  values: string[];
};

// Request
export type ProductSearchParam = {
  perPage: number;
  page: number;
};
export type ProductSearchRequest = {
  phrase: Maybe<string>;
  filters: Filter[];
};

// Response
export type ProductSearchResponse = {
  page: number;
  totalPages: number;
  resultPerPage: number;
  totalItems: number;
  phrase: Maybe<string>;
  filters: Filter[];
  products: Product[];
};
export type ProductSearchFilterResponse = {
  filters: Filter[];
};

/**
 * APIs
 */
// 🔵 POST product/search
export function useApiProductSearch(
  options?: APIOptions<ProductSearchResponse>
) {
  const url = 'product/search';
  const apiProps: UseAPIOnMountProps<
    ProductSearchResponse,
    ProductSearchRequest
  > = {
    url,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<ProductSearchResponse, ProductSearchRequest>(
    apiProps
  );
  const call = async (params: ProductSearchParam, body: ProductSearchRequest) =>
    await api.call({ ...apiProps, url: generateQueryParam(url, params), body });
  return { ...api, call };
}

// 🔵 GET /product/search/filters
export function useApiProductSearchFilters(
  options?: APIOptions<ProductSearchFilterResponse>
) {
  const url = 'product/search/filters';
  const apiProps: UseAPIOnMountProps<ProductSearchFilterResponse> = {
    url: url,
    kind: 'get',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiOnMount<ProductSearchFilterResponse>(apiProps);
  const refetch = async () => await api.refetch({ url });
  return { ...api, refetch };
}
