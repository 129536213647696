import { Maybe } from 'yup';

import { APIOptions } from 'api/hooks/useApiBase';
import { useApiMutation, UseAPIMutationProps } from 'api/hooks/useApiMutation';
import { Product } from 'api/product.api';
import { EncodedFile } from 'util/fileProcessor';

/**
 * Types
 */
// Response
export type UploadCsvResponse = {
  uploadId?: string;
  products: Product[];
  error?: string;
};

// 🔵 POST upload/{customer_id}
export function useApiUploadCsv(
  customerId?: Maybe<string>,
  options?: APIOptions<UploadCsvResponse>
) {
  // Props
  const url = `upload/${customerId ?? ''}`;
  const apiProps: UseAPIMutationProps<UploadCsvResponse, EncodedFile> = {
    url,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<UploadCsvResponse, EncodedFile>(apiProps);
  const call = async (body?: EncodedFile) =>
    await api.call({ ...apiProps, body });
  return { ...api, call };
}

// 🔵 DELETE upload/{upload_id}
export function useApiDeleteUpload(
  uploadId: Maybe<string>,
  options?: APIOptions<null>
) {
  // Props
  const url = `upload/${uploadId}`;
  const apiProps: UseAPIMutationProps<null> = {
    url,
    kind: 'delete',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<null>(apiProps);
  const call = async () => await api.call({ ...apiProps });
  return { ...api, call };
}
