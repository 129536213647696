import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';

import {
  AuthState,
  OktaAuth,
  SigninWithCredentialsOptions
} from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { WrapperProps } from '@reece/global-types';
import { noop } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { Maybe } from 'yup';

/**
 * Types
 */
export type AuthContextType = {
  authState?: Maybe<AuthState>;
  email?: Maybe<string>;
  login?: (values: SigninWithCredentialsOptions) => Promise<void>;
  oktaAuth?: Maybe<OktaAuth>;
  sessionId?: Maybe<string>;
  setEmail: (email: Maybe<string>) => void;
  setSessionId: (sid: Maybe<string>) => void;
};

/**
 * Default values
 */
export const defaultAuthContext: AuthContextType = {
  setSessionId: noop,
  setEmail: noop
};

/**
 * Context
 */
export const AuthContext = createContext(defaultAuthContext);
export const useAuthContext = () => useContext(AuthContext);

/**
 * Provider
 */
function AuthProvider({ children }: WrapperProps) {
  /**
   * Custom hooks
   */
  const [sessionId, setSessionId] = useLocalStorage<Maybe<string>>(
    'sessionId',
    null
  );
  const [email, setEmail] = useLocalStorage<Maybe<string>>(
    'sessionEmail',
    null
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();

  /**
   * Callbacks
   */
  const login = useCallback(
    async (values: SigninWithCredentialsOptions) => {
      const { sessionToken } = await oktaAuth.signInWithCredentials(values);
      oktaAuth.signInWithRedirect({ sessionToken });
    },
    [oktaAuth]
  );

  /**
   * Memos
   */
  const providerValues = useMemo(
    () => ({
      authState,
      email,
      login,
      oktaAuth,
      sessionId,
      setEmail,
      setSessionId
    }),
    [authState, email, login, oktaAuth, sessionId, setEmail, setSessionId]
  );

  /**
   * Effects
   */
  // 🟡 Effect - Redirect to /login if logged out
  useEffect(() => {
    if (
      pathname !== '/login' &&
      !sessionId &&
      authState &&
      !authState.isAuthenticated
    ) {
      setEmail(null);
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, sessionId, authState?.isAuthenticated]);

  /**
   * Render
   */
  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
