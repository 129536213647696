import clsx from 'clsx';

import { ProductCatalog } from 'api/catalog.api';
import { Checkbox } from 'components/Checkbox';
import { Image } from 'components/Image';
import { DefaultCell, defaultHeader, TableInstance } from 'components/Table';
import { CloseIcon, TrashIcon } from 'resources/icons';
import { PunchoutReusableContent } from 'util/contents';

/**
 * Types
 */
export type ProductCatalogTable = ProductCatalog & {
  selected: boolean;
};

/**
 * Columns
 */
const catalogColumns = [
  'Delete',
  'Image',
  'Product Name / Description',
  'Part #',
  'UOM',
  'Manufacturer',
  'MFR #',
  'Category 1',
  'Category 2',
  'Category 3'
];
const reviewDeletionColumns = [
  'Action',
  'Image',
  'Product Name / Description',
  'Part #',
  'Manufacturer',
  'MFR #',
  'Category 1',
  'Remove'
];

/**
 * Util
 */
const cellStyle = ({ selected }: ProductCatalogTable) =>
  clsx({ 'bg-support-2-20': selected });

/**
 * Configs
 */
export function catalogListTableConfig(
  data: ProductCatalogTable[],
  toggleProduct: (product: ProductCatalog) => void
) {
  return {
    data,
    columns: catalogColumns,
    config: {
      // Delete
      [catalogColumns[0]]: {
        header: defaultHeader('uppercase text-center'),
        cellStyle,
        cell: ({ selected, ...product }) => (
          <DefaultCell className="flex justify-center h-full">
            <Checkbox
              className="justify-center"
              checked={selected}
              onChange={() => toggleProduct(product)}
              data-testid={`catalog_deletetoggle-${product.id}`}
            />
          </DefaultCell>
        )
      },
      // Image
      [catalogColumns[1]]: {
        header: defaultHeader('uppercase text-center'),
        cellStyle,
        cell: ({ id, product }) => (
          <DefaultCell>
            <Image
              src={product?.imageFullSize ?? '#'}
              alt={id}
              className="max-w-[50px] max-h-[50px]"
            />
          </DefaultCell>
        )
      },
      // Product Name / Description
      [catalogColumns[2]]: {
        header: defaultHeader('uppercase min-w-[220px]'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Part #
      [catalogColumns[3]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cellStyle,
        cell: ({ partNumber }) => (
          <DefaultCell>
            {partNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // UOM
      [catalogColumns[4]]: {
        header: defaultHeader('uppercase'),
        cellStyle,
        cell: ({ uom }) => (
          <DefaultCell className="uppercase">
            {uom ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Manufacturer
      [catalogColumns[5]]: {
        header: defaultHeader('uppercase'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.manufacturer ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // MFR #
      [catalogColumns[6]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.manufacturerPartNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 1
      [catalogColumns[7]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.categoryLevel1Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 2
      [catalogColumns[8]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.categoryLevel2Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 3
      [catalogColumns[9]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.categoryLevel3Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      }
    }
  } as TableInstance<ProductCatalogTable>;
}
export function catalogDeleteTableConfig(
  data: ProductCatalog[],
  deleteDeletedProduct: (product: ProductCatalog) => void
) {
  return {
    data,
    columns: reviewDeletionColumns,
    config: {
      // Action
      [reviewDeletionColumns[0]]: {
        header: defaultHeader('uppercase text-center'),
        cell: () => (
          <DefaultCell className="text-support-2-100 text-center">
            <CloseIcon className="inline-block" />
          </DefaultCell>
        )
      },
      // Image
      [reviewDeletionColumns[1]]: {
        header: defaultHeader('uppercase text-center'),
        cell: ({ id, product }) => (
          <DefaultCell>
            <Image
              src={product?.imageFullSize ?? '#'}
              alt={id}
              className="max-w-[50px] max-h-[50px]"
            />
          </DefaultCell>
        )
      },
      // Product Name / Description
      [reviewDeletionColumns[2]]: {
        header: defaultHeader('uppercase min-w-[220px]'),
        cell: ({ product }) => (
          <DefaultCell>
            {product?.name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Part #
      [reviewDeletionColumns[3]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cell: ({ partNumber }) => (
          <DefaultCell>
            {partNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Manufacturer
      [reviewDeletionColumns[4]]: {
        header: defaultHeader('uppercase'),
        cell: ({ product }) => (
          <DefaultCell>
            {product?.manufacturer ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // MFR #
      [reviewDeletionColumns[5]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cellStyle,
        cell: ({ product }) => (
          <DefaultCell>
            {product?.manufacturerPartNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 1
      [reviewDeletionColumns[6]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cell: ({ product }) => (
          <DefaultCell>
            {product?.categoryLevel1Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Delete
      [reviewDeletionColumns[7]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cell: (product) => (
          <DefaultCell className="text-center">
            <button
              type="button"
              data-testid={`product_search-review-delete-${product.id}`}
              className="text-primary-1-100"
              onClick={() => deleteDeletedProduct(product)}
            >
              <TrashIcon />
            </button>
          </DefaultCell>
        )
      }
    }
  } as TableInstance<ProductCatalog>;
}
