import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import {
  UnsavedModalDisplay,
  useUnsavedContext
} from 'providers/UnsavedProvider';

/**
 * Types
 */
export type UnsavedModalProps = {
  display: UnsavedModalDisplay;
  open: boolean;
};

/**
 * Component
 */
function UnsavedModal(props: UnsavedModalProps) {
  /**
   * Context
   */
  const { closeModal, onLeave, onSave, setUnsaved } = useUnsavedContext();
  /**
   * Callbacks
   */
  // 🟤 Cb - leave button
  const onLeaveButtonPress = () => {
    onLeave();
    setUnsaved(false);
    closeModal();
  };
  // 🟤 Cb - save button
  const openSaveButtonPress = () => {
    onSave();
    closeModal();
  };

  /**
   * Render
   */
  return (
    <Modal
      className="max-w-[592px]"
      open={props.open}
      onClose={closeModal}
      icon={props.display.icon}
      title={props.display.title}
      data-testid="unsaved_changes"
    >
      <div className="text-secondary-2-100 mt-4 mb-6">{props.display.body}</div>
      <div className="flex justify-end gap-4">
        <Button
          type="button"
          title={props.display.leave}
          className="bg-white text-support-2-100 underline"
          onClick={onLeaveButtonPress}
          data-testid="unsaved_changes-leave-button"
        />
        <Button
          type="button"
          title={props.display.save}
          className="bg-primary-1-100 text-white font-medium"
          onClick={openSaveButtonPress}
          data-testid="unsaved_changes-save-button"
        />
      </div>
    </Modal>
  );
}
export default UnsavedModal;
