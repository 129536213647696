import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { useHomeContext } from './HomeProvider';

function DeleteCustomerModal() {
  /**
   * Context
   */
  const { deleteModalOpen, onLeaveDeleteButtonPress, onDeleteButtonPress } =
    useHomeContext();

  /**
   * Render
   */
  return (
    <Modal
      className="max-w-[592px]"
      open={deleteModalOpen}
      onClose={onLeaveDeleteButtonPress}
      title="Delete Customer"
      data-testid="delete_customer"
    >
      <div className="text-secondary-2-100 mt-4 mb-6">
        <p>Are you sure you want to permanently delete this customer?</p>
        <p>You will NOT be able to recover it.</p>
        <p className="mt-4">
          Note: This will remove all catalogs and products for this customer.
        </p>
      </div>
      <div className="flex justify-end gap-4">
        <Button
          type="button"
          title="No, don't delete"
          className="bg-white text-primary-2-100 underline"
          onClick={onLeaveDeleteButtonPress}
          data-testid="delete_customer-leave-button"
        />
        <Button
          type="button"
          title="Yes, I'm sure"
          className="bg-primary-1-100 text-white font-medium"
          onClick={onDeleteButtonPress}
          data-testid="delete_customer-save-button"
        />
      </div>
    </Modal>
  );
}
export default DeleteCustomerModal;
