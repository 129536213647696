import clsx from 'clsx';
import { CheckboxProps } from 'components/Checkbox';

const Checkbox = (props: CheckboxProps) => {
  /**
   * Props
   */
  const { className, label, checked, onChange, 'data-testid': testId } = props;

  /**
   * Render
   */
  return (
    <label className={clsx('flex items-center cursor-pointer', className)}>
      <input
        className="mr-2 h-4 w-4 p-0.5 rounded-sm bg-neutral-200 border-2 border-neutral-400 checked:bg-blue-500 checked:border-blue-500 cursor-pointer"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        data-testid={testId}
      />
      <span data-testid={`${testId}-label`}>{label ?? ''}</span>
    </label>
  );
};

export default Checkbox;
