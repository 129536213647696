import { Link } from 'react-router-dom';

import { Customer } from 'api/customer.api';
import { DefaultCell, defaultHeader, TableInstance } from 'components/Table';
import { TrashIcon } from 'resources/icons';
import { Maybe } from 'yup';
import { PunchoutReusableContent } from 'util/contents';

const columns = [
  'Company Name',
  'Bill-to #',
  'Home Branch',
  'Last Updated',
  'Delete'
];

export function customerListTableConfig(
  toggleDeleteCustomerId: (customerId?: Maybe<string>) => void,
  data?: Customer[]
) {
  return {
    data: data ?? [],
    columns,
    config: {
      [columns[0]]: {
        header: defaultHeader('uppercase'),
        cell: ({ name, id }) => (
          <Link to={`/customer/detail/${id ?? '#'}`}>
            <DefaultCell>{name}</DefaultCell>
          </Link>
        )
      },
      [columns[1]]: {
        header: defaultHeader('uppercase'),
        cell: ({ customerId }) => <DefaultCell>{customerId}</DefaultCell>
      },
      [columns[2]]: {
        header: defaultHeader('uppercase'),
        cell: ({ branchId }) => <DefaultCell>{branchId}</DefaultCell>
      },
      [columns[3]]: {
        header: defaultHeader('uppercase'),
        cell: ({ lastUpdate }) => (
          <DefaultCell>
            {lastUpdate
              ? new Date(lastUpdate).toLocaleDateString('en-us')
              : PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      [columns[4]]: {
        header: defaultHeader('uppercase text-center'),
        cell: ({ id }) => (
          <DefaultCell className="flex justify-center">
            <button
              type="button"
              data-testid={`customer-list-delete-${id}`}
              className="text-gray-400"
              onClick={() => toggleDeleteCustomerId(id)}
            >
              <TrashIcon />
            </button>
          </DefaultCell>
        )
      }
    }
  } as TableInstance<Customer>;
}
