import { Maybe } from 'yup';

import { SuccessResponse } from 'api/core';
import { Customer } from 'api/customer.api';
import { APIOptions } from 'api/hooks/useApiBase';
import { useApiMutation, UseAPIMutationProps } from 'api/hooks/useApiMutation';
import { useApiOnMount, UseAPIOnMountProps } from 'api/hooks/useApiOnMount';
import { Filter, Product } from 'api/product.api';
import { generateQueryParam } from 'util/generateQueryParam';

/**
 * Types
 */
// Data
export type Catalog = {
  id: string;
  status: string;
  fileName: Maybe<string>;
  name: string;
  lastUpdate: string;
  dateArchived: Maybe<string>;
  procSystem: Maybe<string>;
  skuQuantity: Maybe<number>;
};
export type ProductCatalog = {
  id: string;
  lastPullDatetime: Maybe<string>;
  listPrice: Maybe<number>;
  partNumber: Maybe<string>;
  product: Maybe<Product>;
  sellPrice: Maybe<number>;
  skuQuantity: Maybe<number>;
  uom: Maybe<string>;
};
export type ProcurementSystem = {
  name: string;
};
export type TemplatingOptions = {
  templatingOptions: TemplatingOptions[];
};

// Request
export type CatalogDetailParam = {
  catalogId?: string;
  perPage: number;
  page: number;
};
export type CatalogDetailRequest = {
  phrase?: string;
  filters?: Filter[];
  uploadIds?: string[];
};
export type PostCatalogTitleRequest = {
  name: Maybe<string>;
};
export type SaveCatalogRequest = {
  procurementSystem: string;
  name: string;
  uploadIds: string[];
};
export type SaveCatalogResponse = {
  success: boolean;
  catalogId?: string;
};
export type SaveCatalogProductRequest = {
  productIds: string[];
};
export type CatalogTemplatingRequest = {
  catalogId: string;
  templatingOptionId: string;
};
export type DeleteCatalogProductRequest = {
  catalogProductIds: string[];
};
export type TemplatingOptionsList = {
  id: number;
  option: string;
};

// Response
export type CatalogDetailResponse = {
  page: number;
  totalItems: number;
  totalPages: number;
  resultsPerPage: number;
  customer: Maybe<Customer>;
  catalog: Maybe<Catalog>;
  results: ProductCatalog[];
  useRegionBasedTemplating: Maybe<boolean>;
};
export type PostCatalogTitleResponse = SuccessResponse & {
  sessionId: Maybe<string>;
};
export type ProcurementSystemListResponse = SuccessResponse & {
  procurementSystems: ProcurementSystem[];
};
export type TemplatingOptionsListResponse = SuccessResponse & {
  templatingOptions: TemplatingOptionsList[];
};
export type SaveCatalogProductResponse = SuccessResponse & {
  message: Maybe<string>;
};

/**
 * APIs
 */
// 🔵 POST catalog/view/{catalogId}
export function useApiCatalogDetail(
  body: CatalogDetailRequest,
  param: CatalogDetailParam,
  options?: APIOptions<CatalogDetailResponse>
) {
  // Props
  const url = `catalog/view`;
  !param.catalogId && delete param.catalogId;
  const apiProps: UseAPIOnMountProps<
    CatalogDetailResponse,
    CatalogDetailRequest
  > = {
    url: generateQueryParam(url, param),
    kind: 'post',
    skip: !param?.catalogId,
    options: { ...options, auth: true },
    header: {},
    body
  };

  // API
  const api = useApiOnMount(apiProps);
  const refetch = async (
    myParam?: CatalogDetailParam,
    myBody?: CatalogDetailRequest
  ) =>
    await api.refetch({
      ...apiProps,
      url: generateQueryParam(url, myParam ?? param),
      body: myBody ?? body
    });
  return { ...api, refetch };
}

// 🔵 GET procurement-system/list
export function useApiProcurementSystemList(
  options?: APIOptions<ProcurementSystemListResponse>
) {
  const url = 'procurement-system/list';
  const apiProps: UseAPIOnMountProps<ProcurementSystemListResponse> = {
    url: url,
    kind: 'get',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiOnMount<ProcurementSystemListResponse>(apiProps);
  const refetch = async () => await api.refetch({ url });
  return { ...api, refetch };
}

// 🔵 GET templating/options
export function useApiTemplatingOptions(
  options?: APIOptions<TemplatingOptionsListResponse>
) {
  const url = 'catalog/templating/options';
  const apiProps: UseAPIOnMountProps<TemplatingOptionsListResponse> = {
    url: url,
    kind: 'get',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiOnMount<TemplatingOptionsListResponse>(apiProps);
  const refetch = async () => await api.refetch({ url });
  return { ...api, refetch };
}

// 🔵 POST catalog/new/{customerId}
export function useApiSaveCatalog(
  customerId: string,
  options?: APIOptions<SaveCatalogResponse>
) {
  const url = `catalog/new/${customerId}`;
  const apiProps: UseAPIOnMountProps<SaveCatalogResponse> = {
    url,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<SaveCatalogResponse>(apiProps);
  const call = async (body: SaveCatalogRequest) =>
    await api.call({ ...apiProps, body });
  return { ...api, call };
}

// 🔵 POST catalog/{catalogId}/rename
export function useApiPostCatalogTitle(
  catalogId: Maybe<string>,
  options?: APIOptions<PostCatalogTitleResponse>
) {
  // Props
  const url = `catalog/${catalogId}/rename`;
  const apiProps: UseAPIMutationProps<PostCatalogTitleResponse> = {
    url,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<PostCatalogTitleResponse>(apiProps);
  const call = async (body: PostCatalogTitleRequest) =>
    await api.call({ ...apiProps, body });
  return { ...api, call };
}

// 🔵 POST /catalog-product/{catalogId}/create
export function useApiSaveCatalogProduct(
  catalogId?: string,
  options?: APIOptions<SaveCatalogProductResponse>
) {
  const url = `catalog-product/${catalogId}/create`;
  const apiProps: UseAPIOnMountProps<
    SaveCatalogProductResponse,
    SaveCatalogProductRequest
  > = {
    url,
    kind: 'post',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<
    SaveCatalogProductResponse,
    SaveCatalogProductRequest
  >(apiProps);
  const call = async (productIds: string[]) =>
    await api.call({ ...apiProps, body: { productIds } });
  return { ...api, call };
}

// 🔵 POST /catalog/templating/{catalogId}
export function useApiCatalogTemplating(
  param: CatalogTemplatingRequest,
  options?: APIOptions<SuccessResponse>
) {
  // Props
  const url = `catalog/templating/${param.catalogId}`;
  const apiProps: UseAPIOnMountProps<
    SuccessResponse,
    CatalogTemplatingRequest
  > = {
    url: generateQueryParam(url, param),
    kind: 'post',
    skip: !param.catalogId,
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<SuccessResponse, CatalogTemplatingRequest>(
    apiProps
  );
  const call = async (params: CatalogTemplatingRequest) =>
    await api.call({ ...apiProps, url: generateQueryParam(url, params) });
  return { ...api, call };
}

// 🔵 DELETE /catalog-product
export function useApiDeleteCatalogProduct(
  options?: APIOptions<SuccessResponse>
) {
  // Props
  const url = 'catalog-product';
  const apiProps: UseAPIMutationProps<
    SuccessResponse,
    DeleteCatalogProductRequest
  > = {
    url,
    kind: 'delete',
    options: { ...options, auth: true },
    header: {}
  };

  // API
  const api = useApiMutation<SuccessResponse, DeleteCatalogProductRequest>(
    apiProps
  );
  const call = async (body: DeleteCatalogProductRequest) =>
    await api.call({ ...apiProps, body });
  return { ...api, call };
}
