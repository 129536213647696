import { useMemo } from 'react';

import clsx from 'clsx';

import { Toast as ToastType } from 'providers/ToastProvider';
import { WarningIcon, InfoIcon, SuccessIcon, CloseIcon } from 'resources/icons';

/**
 * Types
 */
export type ToastComponentProps = {
  toast: ToastType;
};

/**
 * Component
 */
function Toast({ toast }: ToastComponentProps) {
  /**
   * Memo
   */
  const config = useMemo(() => {
    switch (toast.kind) {
      case 'error':
        return {
          icon: WarningIcon,
          className: 'text-support-2-100 border-support-2-100 bg-support-2-10'
        };
      case 'success':
        return {
          icon: SuccessIcon,
          className: 'text-support-1-100 border-support-1-100 bg-support-1-10'
        };
      default:
        return {
          icon: InfoIcon,
          className: 'text-primary-2-100 border-primary-2-100 bg-primary-2-10'
        };
    }
  }, [toast.kind]);

  /**
   * Render
   */
  return (
    <div
      data-testid={`toast-${toast.id}`}
      className={clsx(
        'flex items-center gap-2 px-4 py-2 border-[1px] rounded drop-shadow-lg max-w-[calc(100vw-2rem)] select-none',
        config.className,
        { 'w-[900px]': toast.size === 'lg' },
        { 'w-[480px]': toast.size !== 'lg' }
      )}
    >
      <config.icon width={32} height={32} className="shrink-0" />
      <span className="flex-1">{toast.message}</span>
      {Boolean(toast.button) && (
        <>
          <button
            onClick={toast.button!.action}
            className="underline text-primary-2-100"
            data-testid={`toast-${toast.id}-button`}
          >
            {toast.button!.display}
          </button>
        </>
      )}
      {Boolean(toast.hasClose) && (
        <span className="pl-2 flex justify-end items-center">
          <div
            className={clsx('h-6 mr-4 bg-transparent', config.className, {
              'border-r-[1px]': toast.button
            })}
          />
          <button
            onClick={toast.onClose}
            data-testid={`toast-${toast.id}-close`}
          >
            <CloseIcon />
          </button>
        </span>
      )}
    </div>
  );
}

export default Toast;
