import { useApiLogout } from 'api/login.api';
import { Button } from 'components/Button';
import { useAuthContext } from 'providers/AuthProvider';
import { useToastContext } from 'providers/ToastProvider';
import { useNavigate } from 'react-router-dom';
import { LogOutIcon, UserIcon } from 'resources/icons';

function Logout() {
  /**
   * Custom hooks
   */
  const navigate = useNavigate();

  /**
   * Context
   */
  const { authState, email, oktaAuth, sessionId, setEmail, setSessionId } =
    useAuthContext();
  const { toast } = useToastContext();
  const oktaClaim = authState?.idToken?.claims;
  const username = oktaClaim?.name ?? oktaClaim?.email ?? email;

  /**
   * API
   */
  // 🟣 API - Logout
  const api = useApiLogout();

  /**
   * Callbacks
   */
  // 🟤 CB - Main logout function
  const logout = async () => {
    // Okta logout
    if (authState?.isAuthenticated) {
      oktaAuth?.signOut();
      return;
    }
    // Email logout pipeline
    const res = await api.call();
    if (!res?.data.success && res?.data.message) {
      toast({ message: res?.data.message, kind: 'error' });
    }
    setEmail(null);
    setSessionId(null);
    navigate('/login');
  };

  /**
   * Render
   */
  return (
    <div>
      {Boolean(username) && (
        <div className="py-3 px-5 text-white font-medium flex items-center justify-start">
          <span className="mr-2">
            <UserIcon />
          </span>
          <span className="truncate" title={username!} data-testid="username">
            {username}
          </span>
        </div>
      )}
      {Boolean(authState?.isAuthenticated || sessionId) && (
        <Button
          iconPosition="left"
          align="justify-start"
          className="w-full text-white font-normal"
          icon={<LogOutIcon />}
          title="Log out"
          onClick={logout}
          data-testid="button-logout"
        >
          Log out
        </Button>
      )}
    </div>
  );
}
export default Logout;
