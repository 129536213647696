import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import { ModalProps } from 'components/Modal/types';
import { CloseIcon } from 'resources/icons';

function Modal(props: ModalProps) {
  /**
   * Props
   */
  const { 'data-testid': testId } = props;

  /**
   * Render
   */
  return (
    <Transition show={props.open} as="div" className="absolute">
      <div
        className="fixed left-0 top-0 right-0 bottom-0 flex items-center justify-center z-50"
        data-testid={`${testId}-wrapper`}
      >
        <Transition.Child
          unmount={false}
          as="div"
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute left-0 top-0 right-0 bottom-0 bg-black/25 -z-10"
            onClick={() => !props.disableClose && props.onClose?.()}
            data-testid={`${testId}-bg`}
          />
        </Transition.Child>
        <Transition.Child
          unmount={false}
          as="div"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-75"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <div
            className={clsx(
              'bg-white p-6 z-0 rounded-md shadow-lg max-h-screen overflow-y-scroll',
              props.className
            )}
            data-testid={`${testId}-container`}
          >
            <div className="flex">
              {
                <span className="text-support-2-100 mt[0.2rem]">
                  {props.icon}
                </span>
              }
              <span
                className="flex-1 text-2xl font-bold pr-4 text-secondary-2-100 ml-3"
                data-testid={`${testId}-title`}
              >
                {props.title ?? ''}
              </span>
              <button
                onClick={() => props.onClose?.()}
                disabled={props.disableClose}
                data-testid={`${testId}-close-button`}
                className="text-primary-1-100 disabled:text-secondary-2-30"
              >
                <CloseIcon />
              </button>
            </div>
            <div>{props.children}</div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}

export default Modal;
