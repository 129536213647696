import { ChangeEvent, useState } from 'react';

import {
  useApiCatalogTemplating,
  useApiTemplatingOptions
} from 'api/catalog.api';

import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { useCatalogContext } from 'pages/Catalog/CatalogProvider';
import { LoadingIcon } from 'components/LoadingIcon';
import { useToastContext } from 'providers/ToastProvider';
import { PunchoutReusableContent } from 'util/contents';

function RegionalModal() {
  /**
   * Custom Hooks
   */
  const navigate = useNavigate();

  /**
   * Context
   */
  const { data, setRegionalOpen, regionalOpen } = useCatalogContext();
  const catalogId = data?.catalog?.id ?? '';
  const { toast } = useToastContext();

  /**
   * State
   */
  const [addProductsDisabled, setAddProductsDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [checkedRadio, setCheckedRadio] = useState('');

  /**
   * API
   */
  // 🟣 API - GET templating options
  const templatingApi = useApiTemplatingOptions({
    onCompleted: ({ data: { templatingOptions } }) => {
      return templatingOptions[0];
    }
  });

  // 🟣 API - Save catalog templating
  const catalogTemplatingApi = useApiCatalogTemplating({
    catalogId: catalogId,
    templatingOptionId: checkedRadio
  });

  /**
   * Callbacks
   */
  // 🟤 CB - Close Modal
  const closeModal = () => setRegionalOpen(false);

  // 🟤 CB - Radio button handler
  const handleChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    setCheckedRadio(currentTarget.value);
    setAddProductsDisabled(false);
  };

  // 🟤 CB - Submit
  const onModalSubmit = async () => {
    setShowLoading(true);
    setAddProductsDisabled(true);

    const res = await catalogTemplatingApi.call({
      catalogId: catalogId,
      templatingOptionId: checkedRadio
    });

    if (res?.data.success && res?.status == 200) {
      closeModal();
      navigate(`/catalog/detail/${catalogId}`);
      navigate(0);
    } else {
      toast({
        message: PunchoutReusableContent.DEFAULT_TOAST_ERROR,
        kind: 'error',
        hasClose: true
      });
    }

    setAddProductsDisabled(false);
    setShowLoading(false);
  };

  /**
   * Render
   */
  return (
    <Modal
      disableClose={showLoading}
      className="max-w-[592px]"
      open={regionalOpen}
      onClose={closeModal}
      title="Add regional-specific products"
      data-testid="catalog_regional"
    >
      <div className="flex flex-col gap-4 mt-4">
        <span className="text-secondary-2-100 text-base">
          Some products are region specific, by selecting{' '}
          <strong>add to current catalog</strong> or{' '}
          <strong>replace existing catalog</strong>, your catalog will be
          modified to include products based on the branch's location
        </span>
      </div>
      <div
        className={
          !showLoading
            ? 'my-8 max-h-[180px] overflow-y-auto overflow-x-hidden flex gap-9'
            : 'hidden'
        }
      >
        {templatingApi.data?.templatingOptions.map((value, index) => (
          <div key={value.option}>
            <input
              type="radio"
              value={value.id}
              checked={checkedRadio === String(value.id)}
              onChange={handleChange}
              data-testid={`catalog_regional-radio${index}`}
            />
            <label className="text-secondary-2-100 text-base ml-4">
              {value.option}
            </label>
          </div>
        ))}
      </div>
      <div
        className={
          showLoading ? 'flex justify-center items-center mb-4' : 'hidden'
        }
      >
        <LoadingIcon size="xxl" data-testid="catalog_save-loading" />
      </div>
      <div className="flex justify-end">
        <Button
          type="button"
          title="Add products"
          className="bg-primary-1-100 text-white"
          disabled={addProductsDisabled}
          onClick={onModalSubmit}
          data-testid="catalog_regional-add-product-button"
        />
      </div>
    </Modal>
  );
}
export default RegionalModal;
