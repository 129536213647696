import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import { Transition } from '@headlessui/react';
import { WrapperProps } from '@reece/global-types';
import { noop, uniqueId } from 'lodash-es';

import ToastComponent from 'providers/subComponents/Toast';

/**
 * Types
 */
export type ToastKind = 'info' | 'success' | 'error';
export type ToastSize = 'sm' | 'lg';
export type ToastButton = {
  display: string;
  noClose?: boolean;
  action: () => void;
};
export type ToastConfig = {
  button?: ToastButton;
  kind?: ToastKind;
  timer?: number;
  message: ReactNode;
  hasClose?: boolean;
  size?: ToastSize;
  onClose?: () => void;
};
export type Toast = ToastConfig & {
  id: string;
  hide: boolean;
};
export type ToastContextType = {
  clear: () => void;
  toast: (config: ToastConfig) => void;
  toasts: Toast[];
};

/**
 * Config
 */
const DEFAULT_TOAST_TIMEOUT = 7000;
const MAX_TOASTS = 10;

/**
 * Context
 */
export const defaultToastContext: ToastContextType = {
  clear: noop,
  toast: noop,
  toasts: []
};
export const ToastContext = createContext(defaultToastContext);
export const useToastContext = () => useContext(ToastContext);

/**
 * Provider
 */
function ToastProvider({ children }: WrapperProps) {
  /**
   * States
   */
  const [toasts, setToasts] = useState<Toast[]>([]);

  /**
   * Refs
   */
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  /**
   * Callbacks
   */
  // 🟤 Cb - Adjust toast visibility by id (used by transition)
  const hideById = (hideId: string, hide = true) => {
    const toasts = toastsRef.current;
    const affectedIndex = toasts.findIndex(({ id }) => id === hideId);
    const mutableToasts = [...toastsRef.current];
    affectedIndex > -1 && (mutableToasts[affectedIndex].hide = hide);
    setToasts(mutableToasts);
  };
  // 🟤 Cb - Delete toast by toast id
  const deleteById = (deleteId: string) => {
    const filtered = toastsRef.current.filter(({ id }) => id !== deleteId);
    setToasts(filtered);
  };
  // 🟤 Cb - Remove all toasts
  const clear = () => setToasts([]);
  // 🟤 Cb - Create new toast
  const toast = useCallback(
    (config: ToastConfig) => {
      // Unique toast id for closeing
      const id = uniqueId('toast_');
      // Build new data model out of config
      const newToast: Toast = { ...config, id, hide: true };
      // Putting a 1ms timeout to patch transition animation
      setTimeout(() => hideById(id, false), 1);
      // onClose also closes the toast itself
      if (config.hasClose || config.onClose) {
        newToast.onClose = () => {
          config.onClose?.();
          hideById(id);
        };
      }
      // Apply close toast to button if noClose is false
      if (config.button && !config.button?.noClose) {
        const action = () => {
          config.button?.action();
          hideById(id);
        };
        newToast.button = { ...config.button, action };
      }
      // Add toast to list
      const startPosition = Math.min(toast.length - MAX_TOASTS, 0);
      const oldToasts = [...toasts].slice(startPosition);
      setToasts([...oldToasts, newToast]);
      // Apply toast timer if declared
      const timer = newToast.timer ?? DEFAULT_TOAST_TIMEOUT;
      if (timer) {
        setTimeout(() => hideById(id), timer);
      }
    },
    [toasts]
  );

  /**
   * Memo
   */
  const value = useMemo<ToastContextType>(
    () => ({ clear, toast, toasts }),
    [toast, toasts]
  );

  /**
   * Render
   */
  return (
    <ToastContext.Provider value={value}>
      {children}
      <div className="fixed top-0 left-[50%] translate-x-[-50%] flex flex-col items-center gap-2 py-2 z-50">
        {toasts.map((item) => (
          <Transition
            appear
            unmount={false}
            show={!item.hide}
            as="div"
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onAnimationEnd={() => item.hide && deleteById(item.id)}
            key={item.id}
          >
            <ToastComponent toast={item} />
          </Transition>
        ))}
      </div>
    </ToastContext.Provider>
  );
}

export default ToastProvider;
