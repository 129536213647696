import { WrapperProps } from '@reece/global-types';
import { useLocation } from 'react-router-dom';

import Menu from 'common/Sidebar/Menu';
import { useAuthContext } from 'providers/AuthProvider';
import logo from 'resources/images/logo-small.svg';
import Logout from 'common/Sidebar/Logout';

/**
 * Component
 */
function Sidebar({ children }: WrapperProps) {
  /**
   * Custom Hooks
   */
  const { pathname } = useLocation();

  /**
   * Context
   */
  const { sessionId, authState } = useAuthContext();

  /**
   * Render
   */
  if ((!sessionId && !authState?.isAuthenticated) || pathname === '/login') {
    return <>{children}</>;
  }
  return (
    <div className="flex min-w-full" data-testid="sidebar_container">
      <div className="bg-primary-1-100 w-[250px] h-screen sticky top-0 flex flex-col">
        <div className="px-4 py-6">
          <img src={logo} alt="logo" />
        </div>
        <div className="mt-1 px-4">
          <h3 className="text-white text-3xl font-medium">Punchout</h3>
          <h6 className="text-white text-sm font-bold uppercase">
            Admin Portal
          </h6>
        </div>
        <div className="px-2 mt-16">
          <Menu />
        </div>
        <div className="px-2 my-4 flex-1 flex flex-col justify-end">
          <Logout />
        </div>
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
}

export default Sidebar;
