import clsx from 'clsx';

import { ContainerProps } from 'common/Container/types';

/**
 * Component
 */
const Container = ({ children, className, ...props }: ContainerProps) => (
  <div
    className="bg-common-background min-h-screen px-6 py-6 overflow-y-scroll"
    data-testid={props['data-testid']}
  >
    <div className={clsx('bg-white w-full py-6 px-8 shadow-md', className)}>
      {children}
    </div>
  </div>
);

export default Container;
