import { Product } from 'api/product.api';
import { Checkbox } from 'components/Checkbox';
import { Image } from 'components/Image';
import { DefaultCell, defaultHeader, TableInstance } from 'components/Table';
import { PlusIcon, TrashIcon } from 'resources/icons';
import { PunchoutReusableContent } from 'util/contents';

/**
 * Types
 */
export type ProductSearchProduct = Product & {
  selected: boolean;
};

/**
 * Columns
 */
const productSearchColumns = [
  'Select',
  'Image',
  'Product Name / Description',
  'Part #',
  'UOM',
  'Manufacturer',
  'MFR #',
  'Category 1',
  'Category 2',
  'Category 3'
];
const reviewProductSearchColumns = [
  'Action',
  'Image',
  'Product Name / Description',
  'Part #',
  'Manufacturer',
  'MFR #',
  'Category 1',
  'Remove'
];

/**
 * Configs
 */
export function productSearchTableConfig(
  data: ProductSearchProduct[],
  updateSelectedProducts: (product: Product) => void
) {
  /**
   * Output
   */
  return {
    data,
    columns: productSearchColumns,
    config: {
      // Checkbox
      [productSearchColumns[0]]: {
        header: defaultHeader('uppercase text-center'),
        cell: ({ selected, ...product }) => (
          <DefaultCell>
            <Checkbox
              className="justify-center"
              checked={selected}
              onChange={() => updateSelectedProducts(product)}
              data-testid={`select-product-${product.id}`}
            />
          </DefaultCell>
        )
      },
      // Image
      [productSearchColumns[1]]: {
        header: defaultHeader('uppercase text-center'),
        cell: ({ imageFullSize, id }) => (
          <DefaultCell className="text-center">
            <Image
              src={imageFullSize ?? '#'}
              alt={id}
              className="max-w-[50px] max-h-[50px] inline-block"
            />
          </DefaultCell>
        )
      },
      // Product Name / Description
      [productSearchColumns[2]]: {
        header: defaultHeader('uppercase min-w-[220px]'),
        cell: ({ name }) => (
          <DefaultCell>{name ?? PunchoutReusableContent.EMPTY}</DefaultCell>
        )
      },
      // Part #
      [productSearchColumns[3]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cell: ({ partNumber }) => (
          <DefaultCell>
            {partNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // UOM
      [productSearchColumns[4]]: {
        header: defaultHeader('uppercase'),
        cell: ({ unspsc }) => (
          <DefaultCell>{unspsc ?? PunchoutReusableContent.EMPTY}</DefaultCell>
        )
      },
      // Manufacturer
      [productSearchColumns[5]]: {
        header: defaultHeader('uppercase'),
        cell: ({ manufacturer }) => (
          <DefaultCell>
            {manufacturer ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // MFR #
      [productSearchColumns[6]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cell: ({ manufacturerPartNumber }) => (
          <DefaultCell>
            {manufacturerPartNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 1
      [productSearchColumns[7]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cell: ({ categoryLevel1Name }) => (
          <DefaultCell>
            {categoryLevel1Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 2
      [productSearchColumns[8]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cell: ({ categoryLevel2Name }) => (
          <DefaultCell>
            {categoryLevel2Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 3
      [productSearchColumns[9]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cell: ({ categoryLevel3Name }) => (
          <DefaultCell>
            {categoryLevel3Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      }
    }
  } as TableInstance<ProductSearchProduct>;
}
export function reviewProductSearchTableConfig(
  data: Product[],
  deleteSelectedProduct: (product: Product) => void
) {
  /**
   * Output
   */
  return {
    data,
    columns: reviewProductSearchColumns,
    config: {
      // Action
      [reviewProductSearchColumns[0]]: {
        header: defaultHeader('uppercase text-center !px-0'),
        cell: () => (
          <DefaultCell className="text-support-1-100 text-center">
            <PlusIcon className="inline-block" />
          </DefaultCell>
        )
      },
      // Image
      [reviewProductSearchColumns[1]]: {
        header: defaultHeader('uppercase text-center'),
        cell: ({ imageFullSize, id }) => (
          <DefaultCell className="text-center !py-0.5">
            <Image
              src={imageFullSize ?? '#'}
              alt={id}
              className="max-w-[50px] max-h-[50px] inline-block"
            />
          </DefaultCell>
        )
      },
      // Product Name / Description
      [reviewProductSearchColumns[2]]: {
        header: defaultHeader('uppercase min-w-[220px]'),
        cell: ({ name }) => (
          <DefaultCell>{name ?? PunchoutReusableContent.EMPTY}</DefaultCell>
        )
      },
      // Part #
      [reviewProductSearchColumns[3]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cell: ({ partNumber }) => (
          <DefaultCell>
            {partNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Manufacturer
      [reviewProductSearchColumns[4]]: {
        header: defaultHeader('uppercase'),
        cell: ({ manufacturer }) => (
          <DefaultCell>
            {manufacturer ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // MFR #
      [reviewProductSearchColumns[5]]: {
        header: defaultHeader('uppercase min-w-[75px]'),
        cell: ({ manufacturerPartNumber }) => (
          <DefaultCell>
            {manufacturerPartNumber ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Category 1
      [reviewProductSearchColumns[6]]: {
        header: defaultHeader('uppercase min-w-[110px]'),
        cell: ({ categoryLevel1Name }) => (
          <DefaultCell>
            {categoryLevel1Name ?? PunchoutReusableContent.EMPTY}
          </DefaultCell>
        )
      },
      // Delete
      [reviewProductSearchColumns[7]]: {
        header: defaultHeader('uppercase'),
        cell: (product) => (
          <DefaultCell className="text-center">
            <button
              type="button"
              data-testid={`product_search-review-delete-${product.id}`}
              className="text-primary-1-100"
              onClick={() => deleteSelectedProduct(product)}
            >
              <TrashIcon />
            </button>
          </DefaultCell>
        )
      }
    }
  } as TableInstance<ProductSearchProduct>;
}
